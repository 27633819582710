/* eslint react/no-direct-mutation-state: "off" */
import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Area, AreaChart, CartesianGrid, Legend, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Button, Form, Icon, Input, Select, Table, Transition } from 'semantic-ui-react'
import { Confirmation, CustomTable, DataPoint, ImageDefault } from '../components'
import { computeGaps, computeHadesData, Default, floatTimeToString, Mobile, timeString } from '../lib/util'
import LastRealTime from '../widgets/LastRealTime'
import LastRealTimeSmall from '../widgets/LastRealTimeSmall'

const { app, machines, machineEdit } = routes

const COLORS = { quantity: '#00B75B', qOptimal: '#EAFAF1', scrap: '#FFD930', sMax: '#FEF9E7' }

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    99: 'Termine Lavori',
}

const REASON_COLORS = ['#FDF2E9', '#E9F7EF', '#F9EBEA']
let max = 0

class Machine extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, machine } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            chartDataReference: [],
            chartData: [],
            chartWorkOrder: '0',
            ...machine,
            machineMinutes: machine ? parseFloat(machine.machineMinutes) : 0,
        }

        if (onCostChange && machine) {
            onCostChange(this.totalCost(), machine.machineId)
        }
    }

    totalCost() {
        let { machineHourlyRate, machineHours, machineMinutes } = this.state
        let total = (machineHours + machineMinutes / 60) * parseFloat(machineHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        total = Math.round(total * 100) / 100
        return total
    }

    componentDidMount() {
        let { machine, machineId, network } = this.props

        if ((!machine || !machine.machineId) && machineId) {
            network.getMachine(machineId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getMachine, network, getFromId, organization, login } = this.props

        let { machineId, machineHourlyRate, chartWorkOrder } = this.state
        if (machineHourlyRate !== prevState.machineHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), machineId)
            }
        }

        // console.log('Machine getMachine fetching', getMachine.fetching)
        if (getMachine && getMachine.fetching !== prevProps.getMachine.fetching && !getMachine.fetching) {
            // console.log('Done calling getMachine')
            if (getMachine.status.success) {
                // console.log(getMachine.data)
                let machine = getMachine.data.data

                this.setState({ ...machine })
                // console.log('Calling Get machine', login, machine)
                if (login && login.data.user && login.data.user.companies) {
                    let company = login.data.user.companies[organization.data[0]]
                    // console.log('Getting from id both filter and not')
                    network.getFromIdFiltering(company + '_machine', `${machine.machineIdentifier}`, [
                        {
                            key: 'timestamp',
                            symbol: '>',
                            value: moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                        },
                    ])
                    network.getFromId(company + '_machine', `${machine.machineIdentifier}`)
                }
                return
            } else {
                // console.log('calling No machine found with this id')
            }
        }

        if (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) {
            this.setState(computeHadesData(this.props))
        }

        if (
            (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) ||
            chartWorkOrder !== prevState.chartWorkOrder
        ) {
            if (getFromId.status.success && getFromId.data) {
                // console.log('getFromId data is:', getFromId)

                let {
                    // getFromId,
                    getFromId: {
                        request_data: { id: requestedMachine },
                        data: { data: hadesData },
                    },
                } = this.props

                if (!this.state.machineIdentifier || this.state.machineIdentifier !== requestedMachine) {
                    // console.log('Machine id:', this.state.machineId, 'requested:', requestedMachine)
                    return null
                }

                // console.log('Data from filtering is', hadesData)

                let fixedData = []
                if (hadesData) {
                    let condensed = []
                    for (let i = 0; i < hadesData.length; i++) {
                        if (i % 1 === 0) {
                            // TODO choose resolution
                            hadesData[i].millis = new Date(hadesData[i].timestamp).getTime() / 1000
                            condensed.push(hadesData[i])
                        }
                    }
                    fixedData = condensed
                }

                // console.log('fixedData is: ', fixedData)

                let _data = []
                if (this.state.buffer) {
                    for (let i in this.state.buffer) {
                        this.state.buffer[i].millis = new Date(this.state.buffer[i].timestamp).getTime() / 1000
                    }
                    _data = [...fixedData, ...this.state.buffer]
                } else {
                    _data = [...fixedData]
                }

                let { chartDataReference, chartData } = this.state

                if (_data.length === 0) return null

                chartDataReference = []
                chartData = []
                _data.sort((a, b) => {
                    return a.millis - b.millis
                })

                // console.log('fixedData is: ', _data)

                for (let d of _data) {
                    let { timestamp, millis, quantity, scrap, reason, id, sub_id, terminal } = d

                    if (sub_id !== chartWorkOrder) {
                        // console.log('Skipping data of different work order (', sub_id, ')')
                        // Skip data not from this work order
                        continue
                    }

                    if (chartData.some((x) => x.timestamp === timestamp)) {
                        //console.log('already in', timestamp);
                        // if alreasy has that timestamp
                        continue
                    }

                    let maxQty = Math.max(quantity, scrap)
                    if (maxQty > max) max = maxQty

                    if (chartDataReference.length !== 0) {
                        let last = chartDataReference.splice(-1)[0]
                        if (last.id === reason) {
                            last.x2 = millis
                        } else {
                            last.x2 = millis
                            chartDataReference.push({ ...last })
                            last.id = reason
                            last.x1 = millis
                            last.x2 = millis
                            last.label = REASON_LABELS[reason]
                            last.fillColor = REASON_COLORS[reason]
                        }
                        chartDataReference.push(last)
                    } else {
                        let current = {
                            id: reason,
                            label: REASON_LABELS[reason],
                            x1: millis,
                            x2: millis,
                            y1: 0,
                            fillColor: REASON_COLORS[reason],
                            opacity: '0.5',
                        }
                        chartDataReference.push(current)
                    }
                    for (let item of chartDataReference) {
                        item.y2 = max + 50
                    }
                    chartData.push({
                        timestamp,
                        millis,
                        corretti: quantity,
                        scarti: scrap,
                        commessa: id,
                        lavoro: sub_id,
                        macchina: terminal,
                    })
                }
                let { quantity, scrap } = _data.pop()
                let radialQuantity = [
                    {
                        name: 'Ottimale',
                        value: 1000,
                        fill: COLORS.qOptimal,
                    },
                    {
                        name: 'quatita',
                        value: quantity,
                        fill: COLORS.quantity,
                    },
                ]
                let radialScrap = [
                    {
                        name: 'Massimo',
                        value: 500,
                        fill: COLORS.sMax,
                    },
                    {
                        name: 'scarti',
                        value: scrap,
                        fill: COLORS.scrap,
                    },
                ]

                return this.setState({
                    chartData: [...chartData],
                    chartDataReference: [...chartDataReference],
                    buffer: [],
                    radialQuantity,
                    radialScrap,
                })
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { machineName, machineHourlyRate, machineHours, machineMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{machineName}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={machineHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    machineHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{machineHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(machineHours, machineMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideMachine, hideMachineHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideMachine && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideMachineHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideMachine, hideMachineHours, isBalance } = this.props
        let { machineName, machineHours, machineMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideMachine && <Table.Cell>{machineName}</Table.Cell>}
                    {!hideMachineHours && <Table.Cell>{timeString(machineHours, machineMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, machine } = this.props

        network.deleteMachine(machine)

        this.setState({ deleting: false })
    }

    renderCardContent(direction) {
        let { type, noActions, role } = this.props

        let { machineName, machineHourlyRate, machineImage } = this.state

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        return (
            <View fullw {...extraViewProps}>
                <div style={styles.imageContainer}>
                    <ImageDefault fromRoot src={machineImage} ui={false} style={styles.machineImage} />
                </div>
                <View fullw column>
                    <span style={styles.header}>{machineName}</span>
                    {!noActions && (!role || role === 'admin') && !!machineHourlyRate && (
                        <Box textStyle="description">Prezzo orario macchina: {machineHourlyRate}€/h</Box>
                    )}
                </View>
            </View>
        )
    }

    renderControls() {
        let { grouped, chartWorkOrder } = this.state

        let options = []
        for (let i in grouped) {
            options.push({
                key: i,
                value: i,
                text: i,
            })
        }

        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-job-type"
                        control={Select}
                        options={options}
                        label={<span className="label">Ordine di lavoro</span>}
                        placeholder="Ordine di lavoro"
                        name="chartWorkOrder"
                        value={chartWorkOrder}
                        onChange={(e, data) => {
                            let id = data.value
                            this.setState({ chartWorkOrder: id })
                        }}
                    />
                </Form.Group>
            </Form>
        )
    }

    renderCharts() {
        let { chartData, chartDataReference } = this.state

        // console.log('Chart data is', chartData, chartDataReference)

        return (
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                    data={chartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    onMouseOver={() => {
                        this.setState({ stopChart: true })
                    }}
                    onMouseLeave={() => {
                        this.setState({ stopChart: false })
                    }}
                >
                    <defs>
                        <linearGradient id="colorCorretti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.quantity} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.quantity} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorScarti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.scrap} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.scrap} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                        type="number"
                        dataKey="millis"
                        tickFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend verticalAlign="top" height={36} />
                    <Tooltip
                        labelFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />

                    {chartDataReference.map((value, index) => {
                        return (
                            <ReferenceArea
                                key={index}
                                ifOverflow="extendDomain"
                                label={value.label}
                                x1={value.x1}
                                x2={value.x2}
                                y1={value.y1}
                                y2={value.y2}
                                fill={value.fillColor}
                                fillOpacity={value.opacity}
                            />
                        )
                    })}
                    <Area type="monotone" dataKey="corretti" stroke={COLORS.quantity} fillOpacity={1} fill="url(#colorCorretti)" />
                    <Area type="monotone" dataKey="scarti" stroke={COLORS.scrap} fillOpacity={1} fill="url(#colorScarti)" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    renderDataTable() {
        let { grouped } = this.state
        let { isBalance } = this.props

        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        let orderBy = (a, b) => {
            return a.millis - b.millis
        }

        const stats = computeGaps(grouped)

        return <CustomTable {...extraProps} orderBy={orderBy} name="Dati" shorthand="data" values={stats} child={DataPoint} subidName={`Commessa`} />
    }

    render() {
        let { login, type, machine, onClick, selected, noActions, showNoMachine, onOpened, forcedOpen, table, negribossi, wittmann } = this.props

        let { deleting, machineId, machineName, machineHourlyRate, machineImage, machineHours, machineMinutes } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(machine)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        if (machine && machine.machineId === 1 && !showNoMachine) {
            return null
        }

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View noflex fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.machineCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa macchina?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.machineContent}>
                                <View column fullw fullh>
                                    <Default>{this.renderCardContent()}</Default>
                                    <Mobile>{this.renderCardContent('column')}</Mobile>
                                </View>

                                {!!table && negribossi && (
                                    <View style={{ minHeight: 103 }} fullw row around>
                                        <LastRealTime
                                            table={table}
                                            time={moment().format('YYYY-MM-DDHH:mm:ss')}
                                            label={<span className="label">Buoni</span>}
                                            field="jobgoodpartscounter"
                                            format={(value) => {
                                                return value ? value : 0
                                            }}
                                        />
                                        <LastRealTime
                                            table={table}
                                            time={moment().format('YYYY-MM-DDHH:mm:ss')}
                                            label={<span className="label">Scarti</span>}
                                            field="jobbadpartscounter"
                                            format={(value) => {
                                                return value ? value : 0
                                            }}
                                        />
                                        <LastRealTime table={table} time={moment().format('YYYY-MM-DDHH:mm:ss')} label="Commessa" field="jobname" />
                                    </View>
                                )}

                                {!!table && wittmann && (
                                    <View style={{ minHeight: 103 }} fullw row around>
                                        <LastRealTime
                                            table={table}
                                            time={moment().format('YYYY-MM-DDHH:mm:ss')}
                                            label={<span className="label">Buoni</span>}
                                            field="count"
                                            format={(value) => {
                                                return value ? value : 0
                                            }}
                                        />
                                        <LastRealTime
                                            table={table}
                                            time={moment().format('YYYY-MM-DDHH:mm:ss')}
                                            label={<span className="label">Cicli</span>}
                                            field="actcntcyc"
                                            format={(value) => {
                                                return value ? value : 0
                                            }}
                                        />
                                        <LastRealTime
                                            table={table}
                                            time={moment().format('YYYY-MM-DDHH:mm:ss')}
                                            label={<span className="label">Commessa</span>}
                                            field="setdescjob"
                                            format={(value) => {
                                                return value ? value : '--'
                                            }}
                                        />
                                    </View>
                                )}
                            </Box>
                            {!!table && (
                                <Box textStyle="content" extra>
                                    <LastRealTimeSmall
                                        table={table}
                                        time={moment().format('YYYY-MM-DDHH:mm:ss')}
                                        label={<span className="label">Ultimo aggiornamento</span>}
                                        field="timestamp"
                                        format={(value) => {
                                            return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
                                        }}
                                    />
                                </Box>
                            )}
                            {!table && !noActions && (
                                <Box textStyle="content" extra>
                                    {!forcedOpen && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                if (onOpened) {
                                                    onOpened(
                                                        {
                                                            machineId,
                                                            machineName,
                                                            machineImage,
                                                            machineHourlyRate,
                                                            machineHours,
                                                            machineMinutes,
                                                        },
                                                        true,
                                                        () => {
                                                            this.props.history.push(route_from(app, machines, `${machineId}`))
                                                        }
                                                    )
                                                } else {
                                                    this.props.history.push(route_from(app, machines, `${machineId}`))
                                                }
                                            }}
                                        >
                                            <Icon name="folder open" />
                                            <Default>Apri</Default>
                                        </Button>
                                    )}
                                    {user && !!user.manage_machine && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, machines, machineEdit, `${machineId}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            <Default>Modifica</Default>
                                        </Button>
                                    )}

                                    {user && !!user.manage_machine && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            <Default>Elimina</Default>
                                        </Button>
                                    )}
                                </Box>
                            )}
                            {type === 'full' && forcedOpen && (
                                <Transition visible={forcedOpen ? true : this.state.showDetails} animation="slide down" duration={200}>
                                    <Box textStyle="content">
                                        <View fullw column>
                                            {this.renderControls()}
                                            {this.renderCharts()}
                                            {this.renderDataTable()}
                                        </View>
                                    </Box>
                                </Transition>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    machineImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    machineContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    machineCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, organization, getMachine, getFromId, getFromIdFiltering } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        role: rcode,
        getMachine,
        organization,
        getFromId,
        getFromIdFiltering,
        login,
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Machine)))
