import moment from 'momentconfig'
import { useMediaQuery } from 'react-responsive'
import { SORTS_ACTIONS } from '../actions/sorts'
import { FILTERS_ACTIONS } from '../actions/filters'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { SENDNOTIFICATION_ACTIONS } from '../actions/sendNotification'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { route_from, routes } from 'lib/routes'
const { app } = routes

export const mapOrdToOrdering = {
    asc: 'ascending',
    desc: 'descending',
}

export const roundToDigits = (number, digits = 2) => {
    const multiplier = 10 ^ digits
    return Math.round(number * multiplier) / multiplier
}

export const goTo = (history, to) => {
    history.replace(route_from(app, to))
}

export const goToFrom = (history, from, to) => {
    history.replace(route_from(from, to))
}

export const baseViewDispatch = (dispatch) => ({
    sortsRequest: (result) => SORTS_ACTIONS.request(result, dispatch),
    sortsResult: (result) => SORTS_ACTIONS.result(result, dispatch),
    sortsError: (result) => SORTS_ACTIONS.error(result, dispatch),

    filtersRequest: (result) => FILTERS_ACTIONS.request(result, dispatch),
    filtersResult: (result) => FILTERS_ACTIONS.result(result, dispatch),
    filtersError: (result) => FILTERS_ACTIONS.error(result, dispatch),

    toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
    toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
    toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

    searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
    searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
    searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

    sendNotificationRequest: (result) => SENDNOTIFICATION_ACTIONS.request(result, dispatch),
    sendNotificationResult: (result) => SENDNOTIFICATION_ACTIONS.result(result, dispatch),
    sendNotificationError: (result) => SENDNOTIFICATION_ACTIONS.error(result, dispatch),

    show: (notif) => dispatch(show(notif)),
    success: (notif) => dispatch(success(notif)),
    error: (notif) => dispatch(error(notif)),
    warning: (notif) => dispatch(warning(notif)),
    info: (notif) => dispatch(info(notif)),
    hide: (notif) => dispatch(hide(notif)),
    removeAll: (notif) => dispatch(removeAll(notif)),
})

export const initializePage = (self, toolbar, filters, sorts) => {
    let props = self.props
    if (!self.props) {
        props = self
    }

    if (!props.sub) {
        const { toolbarResult, filtersResult, sortsResult } = props
        if (toolbar) {
            toolbarResult(toolbar)
        }
        if (filters) {
            filtersResult(filters)
        }
        if (sorts) {
            sortsResult(sorts)
        }
    }
}

export const checkFiltersChanged = (filters, prevFilters) => {
    for (const key in filters) {
        if (!(key in prevFilters)) return true

        if (filters[key] !== prevFilters[key]) return true
    }

    return false
}

export const formatMoney = (value) => {
    return parseFloat(value).toFixed(2)
}

export const addVat = (value) => {
    return parseFloat(value) * 1.22
}

export const formatMoneyVat = (value) => {
    return (parseFloat(value) * 1.22).toFixed(2).replace('.', ',')
}

export const getAmount = (price, qty, discount) => {
    if (parseInt(qty) === 0) return 0
    if (discount === 0) return price * qty

    discount = discount.toString().split('+')
    // console.log('discount', discount)
    for (let d of discount) {
        d = parseInt(d)
        price = price - (price * d) / 100
    }
    return price * qty
}

// const getReasonDescription = (documentreason, id) => {
// 	let reasonValueObj = documentreason.filter(x => x.id === id)[0] || {}
// 	return reasonValueObj.description || ''
// }

export function buildFileSelector(onChange, format) {
    window.fileCallback = onChange

    const fileSelector = document.createElement('input')
    fileSelector.setAttribute('type', 'file')
    fileSelector.setAttribute('multiple', true)
    //fileSelector.setAttribute('myfunc', onChange);
    //fileSelector.setAttribute('onchange', "fileCallback(e)");
    fileSelector.addEventListener('change', onChange)
    if (format) {
        fileSelector.setAttribute('accept', format)
    }
    //fileSelector.setAttribute('multiple', 'multiple');
    return fileSelector
}

export function floatTimeToObject(float) {
    let number = Math.floor(float)
    let decimal = float - number
    return timeObject(number, Math.round(decimal * 60))
}

export function floatTimeToString(float) {
    let number = Math.floor(float)
    let decimal = float - number
    return timeString(number, Math.round(decimal * 60))
}

export function floatTimeToPaddedString(float) {
    let hours = Math.floor(float)
    let minutes = Math.round((float - hours) * 60)

    let hstring = hours < 10 ? '0' + hours : '' + hours
    let mstring = minutes < 10 ? '0' + minutes : '' + minutes

    return {
        hstring,
        mstring,
        fullstring: `${hstring}:${mstring}`,
    }
}

export function timeObject(hours, minutes) {
    let hstring = ''
    let mstring = ''

    hours = parseInt(hours)
    minutes = parseInt(minutes)
    if (isNaN(hours)) {
        hours = 0
    }

    if (isNaN(minutes)) {
        minutes = 0
    }

    if (hours === 0 && minutes === 0) {
        return {
            hstring: '--',
            mstring: '',
        }
    }

    if (hours === 0) {
        hstring = ''
    } else if (hours === 1) {
        hstring += `${hours} ora`
    } else {
        hstring += `${hours} ore`
    }

    if (minutes === 0) {
        mstring = ''
    } else if (minutes === 1) {
        mstring = `${minutes} minuto`
    } else {
        mstring += `${minutes} minuti`
    }

    return {
        hstring,
        mstring,
    }
}

function stringEmpty(string) {
    return !string || string === '' || string === '--'
}

export function timeString(hours, minutes) {
    let { hstring, mstring } = timeObject(hours, minutes)
    let noHourString = stringEmpty(hstring)
    let noMinString = stringEmpty(mstring)

    if (noHourString && mstring) {
        return mstring
    } else if (hstring && noMinString) {
        return hstring
    } else if (noHourString && noMinString) {
        return 'Tempo di lavoro non registrato'
    }

    return `${hstring} ${mstring}`
}

export function getRandomColor() {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

// https://stackoverflow.com/questions/1484506/random-color-generator
export function rainbow(numOfSteps, step) {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    var r, g, b
    var h = step / numOfSteps
    var i = ~~(h * 6)
    var f = h * 6 - i
    var q = 1 - f
    switch (i % 6) {
        case 0:
            r = 1
            g = f
            b = 0
            break
        case 1:
            r = q
            g = 1
            b = 0
            break
        case 2:
            r = 0
            g = 1
            b = f
            break
        case 3:
            r = 0
            g = q
            b = 1
            break
        case 4:
            r = f
            g = 0
            b = 1
            break
        case 5:
            r = 1
            g = 0
            b = q
            break
        default:
            break
    }
    var c =
        '#' +
        ('00' + (~~(r * 255)).toString(16)).slice(-2) +
        ('00' + (~~(g * 255)).toString(16)).slice(-2) +
        ('00' + (~~(b * 255)).toString(16)).slice(-2)
    return c
}

export function alertGenerator({ title, message, button, callback, noDismiss }) {
    return createAlert(title, message, button, callback, noDismiss)
}

export function createAlert(title, message, button, callback, noDismiss) {
    let notif = {
        title,
        message,
        position: 'br',
        autoDismiss: !noDismiss ? 5 : 0,
    }

    if (button) {
        notif.action = {
            label: button,
            callback,
        }
    }

    return notif
}

export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
export const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export function computeHadesData(props) {
    // Computing from hades data
    // let { dataPoints } = this.state

    let {
        getFromId: {
            data: { data: hadesData },
        },
    } = props

    let grouped = {}
    let hadesMachines = {}
    let hadesWorkers = {}
    let gapSize = 8 * 3600 * 1000 // Eight hours
    // let gapSize = 3600 * 24000

    let gapData = []
    let span = [null, null]
    let prevData = null

    let machineGapData = []
    let machineSpan = [null, null]

    let workerGapData = []
    let workerSpan = [null, null]

    if (!hadesData) return null

    hadesData = [...hadesData].reverse()
    // console.log('GAP CALC HADES DATA:', hadesData)

    if (hadesData && hadesData[0] && hadesData[0].quantity !== 0) {
        hadesData.unshift({
            ...hadesData[0],
            quantity: 0,
        })
        // hadesData[0].quantity = 0
    }

    for (let i in hadesData) {
        let data = hadesData[i]

        // Grouping data by work order
        if (!grouped[data.sub_id]) {
            grouped[data.sub_id] = {
                workorder: data.sub_id,
                job: data.id,
                phases: {},
            }
        }
        if (!grouped[data.sub_id].phases[data.reason]) {
            grouped[data.sub_id].phases[data.reason] = {
                data: [],
                phase: data.reason, // TODO reason to phase name
                workSpans: [],
            }
        }
        grouped[data.sub_id].phases[data.reason].data.push(data)

        // Grouping data by hadesMachines (terminals)
        if (!hadesMachines[data.terminal]) {
            hadesMachines[data.terminal] = {
                machineIdentifier: data.terminal,
                // machineName: `Macchina ${data.terminal}`, // nome macchina dal database
                data: [],
                workSpans: [],
            }
        }
        hadesMachines[data.terminal].data.push(data)

        // Grouping data by worker
        if (!hadesWorkers[data.operator]) {
            hadesWorkers[data.operator] = {
                workerIdentifier: data.operator,
                // workerName: `Operatore ${data.operator}`, // nome operatore dal database
                data: [],
                workSpans: [],
            }
        }
        hadesWorkers[data.operator].data.push(data)

        let nextDate = moment(data.timestamp)

        // console.log('Hades data length:', hadesData.length - 1, 'vs i:', i, hadesData.length - 1 === i)

        if (checkGap(grouped, gapSize, gapData, span, prevData, data, null, hadesData.length - 1 === i)) {
            // console.log('grouped gap data is:', gapData)
            let newGapData = []
            if (gapData.length > 0) {
                newGapData.push(gapData[gapData.length - 1])
            }
            newGapData.push(data)
            gapData = newGapData
            span = [span[1], null]
        }

        if (checkGap(hadesMachines[data.terminal], gapSize, machineGapData, machineSpan, prevData, data, 'machine', hadesData.length - 1 === i)) {
            let spans = hadesMachines[data.terminal].workSpans
            let currentSpan = spans[spans.length - 1]
            let span = [currentSpan.data[currentSpan.data.length - 1].timestamp, currentSpan.data[0].timestamp]
            // console.log('SPAN IS:', span)

            let ms = moment(span[1]).diff(moment(span[0]))
            let size = Math.abs(moment.duration(ms).valueOf())
            // console.log('SPAN IS SIZE:', size)
            // console.log('SPAN IS DATA:', currentSpan.data)
            currentSpan.size = size

            let newGapData = []
            if (machineGapData.length > 0) {
                newGapData.push(machineGapData[machineGapData.length - 1])
            }
            newGapData.push(data)
            machineGapData = newGapData
            machineSpan = [machineSpan[1], null]
        }

        if (checkGap(hadesWorkers[data.operator], gapSize, workerGapData, workerSpan, prevData, data, 'worker', hadesData.length - 1 === i)) {
            let spans = hadesWorkers[data.operator].workSpans
            let currentSpan = spans[spans.length - 1]
            let span = [currentSpan.data[currentSpan.data.length - 1].timestamp, currentSpan.data[0].timestamp]
            // console.log('SPAN IS:', span)

            let ms = moment(span[1]).diff(moment(span[0]))
            let size = Math.abs(moment.duration(ms).valueOf())
            // console.log('SPAN IS SIZE:', size)
            // console.log('SPAN IS DATA:', currentSpan.data)
            currentSpan.size = size

            let newGapData = []
            if (workerGapData.length > 0) {
                newGapData.push(workerGapData[workerGapData.length - 1])
            }
            newGapData.push(data)
            workerGapData = newGapData
            workerSpan = [workerSpan[1], null]
        }

        if (!span[0]) {
            span[0] = nextDate
        }

        prevData = data
    }

    // console.log('GAP CALC Get from id data is')
    // console.log('GAP CALC Grouped', grouped)
    // console.log('GAP CALC Machines', hadesMachines)
    // console.log('GAP CALC Workers', hadesWorkers)

    let firstWorkOrder = Object.keys(grouped)[0]
    return {
        grouped,
        hadesWorkers,
        hadesMachines,
        chartWorkOrder: firstWorkOrder ? firstWorkOrder : '0',
    }
}

export function checkGap(grouped, gapSize, gapData, span, prevData, newData, method, forced) {
    let prevDate = prevData ? moment(prevData.timestamp) : null

    // console.log('GAP CALC prev:', prevData ? prevData.reason : null, newData.reason, newData.quantity)

    let gap = 0
    if (prevDate) {
        let ms = moment(newData.timestamp).diff(prevDate)
        gap = Math.abs(moment.duration(ms).valueOf())
    }

    let result = false

    let methodCondition =
        prevData &&
        ((method === 'machine' && prevData.terminal !== newData.terminal) || (method === 'worker' && prevData.operator !== newData.operator))

    if ((prevData && (gap > gapSize || prevData.reason !== newData.reason || prevData.sub_id !== newData.sub_id)) || methodCondition || forced) {
        // /*
        // console.log('GAP CALC Gap duration:', gap, 'prevData', prevData, 'nextData', newData)
        // console.log('Conditions:', gap > gapSize, prevData && prevData.reason !== newData.reason, prevData && prevData.sub_id !== newData.sub_id)
        // */
        span[1] = prevDate
        let obj = {
            span,
            gapFromLast: gap,
            data: gapData,
            // size
        }
        // console.log("sub_id is", prevData.sub_id, grouped)
        // console.log('grouped reason is:', prevData.reason)
        if (prevData && grouped[prevData.sub_id] && grouped[prevData.sub_id].phases) {
            grouped[prevData.sub_id].phases[prevData.reason].workSpans.push(obj)
        } else {
            if (grouped.workSpans) {
                grouped.workSpans.push(obj)
            }
        }
        result = true
    }

    if (!result) {
        gapData.push(newData)
    } else if (forced) {
        gapData.push({
            ...newData,
        })
    }
    return result
}

export function computeGaps(grouped) {
    const stats = []
    for (let key in grouped) {
        let workorder = grouped[key]
        for (let phaseid in workorder.phases) {
            let phase = workorder.phases[phaseid]

            let phaseQuantity = 0
            let phaseScrap = 0

            for (let span in phase.workSpans) {
                // Data points are in reverse chronological order (newest first)
                let lastDataPoint = phase.workSpans[span].data[0]
                let firstDataPoint = phase.workSpans[span].data[phase.workSpans[span].data.length - 1]

                let q = firstDataPoint.quantity - lastDataPoint.quantity
                let s = firstDataPoint.scrap - lastDataPoint.scrap
                phase.workSpans[span].quantity = q
                phase.workSpans[span].scrap = s

                phaseQuantity += q
                phaseScrap += s

                // console.log("GAP CALC computeGaps first:", firstDataPoint)
                // console.log("GAP CALC computeGaps last:", lastDataPoint)
                stats.push({
                    ...firstDataPoint,
                    reason: phaseid,
                    quantity: q,
                    scrap: s,
                    startstamp: lastDataPoint.timestamp,
                    endstamp: firstDataPoint.timestamp,
                })
            }

            phase.quantity = phaseQuantity
            phase.scrap = phaseScrap

            // stats.push({
            // 	...phase.data[phase.data.length - 1],
            // 	quantity: phaseQuantity,
            // 	scrap: phaseScrap
            // })
        }
    }

    return stats
}

export const testContract = {
    contractId: 1,
    contractName: 'Titolo appalto',
    contractDescription: 'Descrizione appalto',
    contractImage: '/uploads/3269f4188a95437e9dfdc4ec0cd61ad5',
    creationDate: '2019-11-23 15:42:00',
    updatedAt: '2019-11-23 18:11:00',
    dueDate: '2019-12-24 00:00:00',
    open: 1,
    deleted: 0,
    contractOwner: {
        organizationId: 'test_company',
        organizationName: 'Boring Company',
    },
    contractData: [
        {
            contractDataId: 1,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'text',
                contractDataTypeName: 'Paragrafo',
            },
            textContent: 'Contenuto 1 per il primo appalto, paragrafo di testo\n\nEsperimento con andate a capo\n\n\nSta funzionando?',
        },
        {
            contractDataId: 2,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'image',
                contractDataTypeName: 'Immagine',
            },
            textContent: 'Immagine descrittiva della postazione',
            fileContent: '/uploads/3269f4188a95437e9dfdc4ec0cd61ad5',
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'csv',
                contractDataTypeName: 'File CSV',
            },
            textContent: 'File CSV',
            fileContent: '/uploads/3269f4188a95437e9dfdc4ec0cd61ad5',
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'file',
                contractDataTypeName: 'File',
            },
            textContent: 'File generico',
            fileContent: '/uploads/3269f4188a95437e9dfdc4ec0cd61ad5',
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'excel',
                contractDataTypeName: 'File Excel',
            },
            textContent: 'File Excel',
            fileContent: '/uploads/3269f4188a95437e9dfdc4ec0cd61ad5',
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'pdf',
                contractDataTypeName: 'File PDF',
            },
            textContent: 'File PDF',
            fileContent: 'https://demo.dahercules.syncontractId.it//uploads/3269f4188a95437e9dfdc4ec0cd61ad5',
        },
        {
            contractDataId: 100,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'newline',
                contractDataTypeName: 'Forza nuova riga',
            },
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'date',
                contractDataTypeName: 'Data',
            },
            dateContent: '2019-12-12 18:00:00',
            textContent: 'Data rilevante',
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'deadline',
                contractDataTypeName: 'Deadline',
            },
            dateContent: '2019-12-24 18:00:00',
            textContent: 'Data consegna',
        },
        {
            contractDataId: 3,
            contractId: 1,
            contractDataType: {
                contractDataTypeKey: 'milestone',
                contractDataTypeName: 'Milestone',
            },
            dateContent: '2019-12-10 18:00:00',
            textContent: 'Data completamento prima demo',
        },
    ],
}
