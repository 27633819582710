import { Jobs } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Step, Table, Segment, Header } from 'semantic-ui-react'
import { Articles } from '../containers'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import Material from './Material'
import Mold from './Mold'
function MaterialCheck(props) {
    const { network, worker, toolbarResult, filtersResult, sortsResult, isEmployee } = props
    const [scannedMaterials, setScannedMaterials] = useState([])
    const [scannedCode, setScannedCode] = useState('')
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [selectedJob, setSelectedJob] = useState(null)
    const [selectedWorkOrder, setSelectedWorkOrder] = useState(null)
    const [step, setStep] = useState(0)

    useEffect(() => {
        props.searchResult(scannedCode)
    }, [scannedCode])

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    useEffect(() => {
        setImmediate(() => {
            const scannedCodeEl = document.getElementById('form-input-control-article-code')
            if (scannedCodeEl) {
                scannedCodeEl.focus()
                scannedCodeEl.onblur = (event) => {
                    setTimeout(() => {
                        var blurEl = document.getElementById('form-input-control-article-code')
                        if (blurEl) {
                            blurEl.focus()
                        }
                    }, 10)
                }
            }
        })
    }, [step])

    // if (step === 1) {
    //     if (selectedArticle && selectedArticle.molds) {
    //         for (const mold of selectedArticle.molds) {
    //             if (mold.moldCode === scannedCode) {
    //                 setScannedCode('')
    //                 setStep(2)
    //             }
    //         }
    //     } else {
    //         setStep(2)
    //     }
    // }

    if (step === 1) {
        if (selectedWorkOrder && selectedWorkOrder.materials) {
            for (const material of selectedWorkOrder.materials) {
                if (material.materialCode === scannedCode) {
                    setScannedMaterials([...scannedMaterials, material.moldCode])
                }
            }
            if (scannedMaterials.length === selectedWorkOrder.materials.length) {
                setScannedCode('')
                setStep(2)
            }
        } else {
            setScannedCode('')
            setStep(2)
        }
    }

    return (
        <>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Scansione</span>}
                        name="scannedCode"
                        value={scannedCode}
                        onChange={(e) => {
                            setScannedCode(e.target.value)
                        }}
                        placeholder="SCANSIONA QUI"
                        style={{
                            fontSize: 64,
                        }}
                        autoComplete="off"
                    />
                </Form.Group>
            </Form>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="file alternate outline" />
                    <Step.Content>
                        <Step.Title>Articolo</Step.Title>
                        <Step.Description>Scansiona l{"'"}ordine</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1}>
                    <Icon name="cube" />
                    <Step.Content>
                        <Step.Title>Materiale</Step.Title>
                        <Step.Description>Scansiona il materiale corretto</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={step === 2}>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description>Invia in macchina</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Table celled>
                {step === 0 && (
                    <>
                        <Jobs
                            workerId={worker}
                            noActions
                            noBarcode
                            sub
                            searchable
                            selectable
                            style={{ height: '100%', overflowY: 'auto' }}
                            onChange={(job, workorders) => {
                                /* eslint-disable */
                                console.log('Steps chose job', job, workorders)
                                // this.next()
                                // this.setState({ job })
                                setSelectedJob(job)
                                setSelectedWorkOrder(workorders[job.jobCode][0])
                                setScannedCode('')
                                setStep(1)
                            }}
                            isEmployee
                        />
                        {/* <Articles
                            isEmployee={isEmployee}
                            noActions
                            selectable
                            onChange={(article) => {
                                // console.log('Selected article', article)
                                setSelectedArticle(article)
                                setScannedCode('')
                                setStep(1)
                            }}
                            jobCode={scannedCode}
                            sub
                        /> */}
                    </>
                )}

                {step === 1 && (
                    <>
                        <Material type="header" hideActions hideBarcode />
                        <Table.Body>
                            {/* {selectedArticle && selectedArticle.materials
                                ? selectedArticle.materials.map((value, index) => {
                                      return (
                                          <Material
                                              key={value.materialId}
                                              type="table"
                                              material={value}
                                              hideActions
                                              hideBarcode
                                              isEmployee={isEmployee}
                                              scannedMaterials={scannedMaterials}
                                          />
                                      )
                                  })
                                : null} */}
                            {selectedJob && selectedWorkOrder && selectedWorkOrder.materials
                                ? selectedWorkOrder.materials.map((value, index) => {
                                      return (
                                          <Material
                                              key={value.materialId}
                                              type="table"
                                              material={value}
                                              hideActions
                                              hideBarcode
                                              isEmployee={isEmployee}
                                              scannedMaterials={scannedMaterials}
                                          />
                                      )
                                  })
                                : null}
                        </Table.Body>
                    </>
                )}

                {step === 2 && (
                    <Segment placeholder style={{ backgroundColor: '#006400' }}>
                        <Header icon>
                            <Icon name="check" />
                            Procedura completata con successo!
                        </Header>
                        <Button
                            color="green"
                            onClick={() => {
                                console.log('Sending to machine...', selectedJob, selectedWorkOrder)

                                network.createCommand(
                                    {
                                        node: 'ns=3;s="Variabili in crittura dal gestionale"."Codice commessa  (max20 caratteri)"',
                                        action: 1,
                                        value: `${selectedJob.jobCode}`,
                                        endpoint: 'opc.tcp://192.168.0.13:4840',
                                    },
                                    true
                                )
                                if (selectedWorkOrder && selectedWorkOrder.productQuantity) {
                                    network.createCommand(
                                        {
                                            node: 'ns=3;s="Variabili in crittura dal gestionale"."Pezzi da fare"',
                                            action: 1,
                                            value: `${selectedJob.jobCode}`,
                                            endpoint: 'opc.tcp://192.168.0.13:4840',
                                        },
                                        true
                                    )
                                }

                                network.createCommand(
                                    {
                                        node: 'ns=3;s="Variabili in crittura dal gestionale"."Formato da attivare (max20 caratteri)"',
                                        action: 1,
                                        value: `F1`,
                                        endpoint: 'opc.tcp://192.168.0.13:4840',
                                    },
                                    true
                                )

                                // network.createCommand(
                                //     {
                                //         node: 'ns=3;s="Variabili in crittura dal gestionale"."Velocità di produzione"',
                                //         action: 1,
                                //         value: `${selectedJob.jobCode}`,
                                //         endpoint: 'opc.tcp://192.168.0.13:4840',
                                //     },
                                //     true
                                // )

                                // network.createCommand(
                                //     {
                                //         node: 'ns=3;s="Variabili in crittura dal gestionale"."Reset contatori astucci riempiti"',
                                //         action: 1,
                                //         value: `${selectedJob.jobCode}`,
                                //         endpoint: 'opc.tcp://192.168.0.13:4840',
                                //     },
                                //     true
                                // )

                                // network.createCommand(
                                //     {
                                //         node: 'ns=3;s="Variabili in crittura dal gestionale"."reset astucci scartati"',
                                //         action: 1,
                                //         value: `${selectedJob.jobCode}`,
                                //         endpoint: 'opc.tcp://192.168.0.13:4840',
                                //     },
                                //     true
                                // )

                                setStep(0)
                                setScannedMaterials([])
                            }}
                        >
                            Invia alla macchina
                        </Button>
                    </Segment>
                )}
            </Table>
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && (
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                            setScannedMaterials([])
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {/* {step === 3 && (
                    <Button
                        color="green"
                        onClick={() => {
                            // console.log('Sending to machine...')

                            setStep(0)
                            setScannedMaterials([])
                        }}
                    >
                        Fine
                    </Button>
                )} */}
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MaterialCheck)))
