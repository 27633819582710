import { Box, Spinner } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import WorkOrderEditor from '../job/WorkOrderEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import WorkOrder from './WorkOrder'
import { Center, Square, Circle } from '@chakra-ui/react'

function WorkOrders(props) {
    const {
        network,
        table,
        time,
        label,
        field,
        format,
        toolbarResult,
        filtersResult,
        sortsResult,
        deleteWorkOrder,
        mergeWorkOrder,
        searchText,
        sub,
        selectable,
        onChangeSelection,
        defaultSelectedWorkOrders,
        noActions,
        showHistory,
    } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        workOrder: null,
    })

    const [dirtySelection, setDirtySelection] = useState(false)
    const [workOrders, setWorkOrders] = useState([])
    const [selectedWorkOrders, setSelectedWorkOrders] = useState(defaultSelectedWorkOrders ? defaultSelectedWorkOrders : {})
    const [dirty, setDirty] = useState(false)
    const [editing, setEditing] = useState(false)
    const [creating, setCreating] = useState(false)
    const [loading, setLoading] = useState(false)
    let [page, setPage] = useState(-1)
    const [filters, setFilters] = useState(props.filters ? props.filters : {})
    const [sorts, setSorts] = useState(props.sorts ? props.sorts : {})

    useEffect(() => {
        setLoading(true)
        network.getWorkOrderList(searchText ? searchText : '', filters, sorts, showHistory).then((data) => {
            const workOrders = []
            for (const key in data.data) {
                workOrders.push(data.data[key])
            }
            setWorkOrders(workOrders)
            setDirty(false)
            setLoading(false)
            setPage(0)
        })
    }, [dirty, deleteWorkOrder.fetching, searchText, showHistory]) // mergeWorkOrder.fetching

    useEffect(() => {
        let pageItems = [
            {
                name: `${parseInt(page) + 1}`,
                position: 'none',
                type: 'text',
            },
        ]

        let toolbar = sub
            ? []
            : [
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: () => {
                          if (page >= 0) {
                              setPage(page - 1)
                              page--
                          }
                      },
                  },
                  ...pageItems,
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: () => {
                          setPage(page + 1)
                          page++
                      },
                  },
              ]

        if (!showHistory) {
            toolbar = [
                {
                    name: 'Crea riga ordine',
                    icon: 'plus',
                    position: 'left',
                    action: () => {
                        setCreating(true)
                    },
                },
                ...toolbar,
            ]
        }

        const sortoptions = sub
            ? {}
            : {
                  deliveryDate: {
                      name: 'deliveryDate',
                      label: 'Data di completamento',
                      onChange: (obj, value) => {
                          sorts[obj.name] = value
                          setSorts(sorts)
                          setWorkOrders([])
                          setDirty(!dirty)
                      },
                      onReset: (obj) => {
                          delete sorts[obj.name]
                          setSorts(sorts)
                          setWorkOrders([])
                          setDirty(!dirty)
                      },
                  },
                  creationDate: {
                      name: 'creationDate',
                      label: 'Data di creazione',
                      onChange: (obj, value) => {
                          sorts[obj.name] = value
                          setSorts(sorts)
                          setWorkOrders([])
                          setDirty(!dirty)
                      },
                      onReset: (obj) => {
                          delete sorts[obj.name]
                          setSorts(sorts)
                          setWorkOrders([])
                          setDirty(!dirty)
                      },
                  },
              }

        const filteroptions = sub
            ? {}
            : {
                  workOrderId: {
                      control: Input,
                      name: 'workOrderId',
                      input: 'text',
                      placeholder: '122',
                      label: 'Codice ordine di lavoro interno',
                      onChange: (e) => {
                          filters.workOrderId = { value: e.target.value, key: 'workOrderId', operator: '=' }
                          setFilters(filters)
                          setDirty(!dirty)
                      },
                      onReset: () => {
                          setFilters(filters)
                          setWorkOrders([])
                          setDirty(!dirty)
                      },
                  },
                  articleCode: {
                      control: Input,
                      name: 'articleCode',
                      input: 'text',
                      placeholder: 'ART153',
                      label: 'Codice articolo',
                      onChange: (e) => {
                          filters.articleCode = { value: e.target.value, key: 'articleCode', operator: '=' }
                          setFilters(filters)
                          setDirty(!dirty)
                      },
                      onReset: () => {
                          setFilters(filters)
                          setWorkOrders([])
                          setDirty(!dirty)
                      },
                  },
              }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [page])

    if (loading) {
        return (
            <Box w="100%" h="300px" mt="32px">
                <Center>
                    <Spinner size="xl" />
                </Center>
            </Box>
        )
    }

    return (
        <>
            {workOrders && workOrders.length > 0 ? (
                <Table celled sortable>
                    <WorkOrder
                        type="header"
                        sorts={sorts}
                        selectable={!!selectable}
                        onWorkOrderDeliveryDateHeaderClick={() => {
                            if (!sorts.deliveryDate) {
                                sorts.deliveryDate = 'asc'
                            } else if (sorts.deliveryDate === 'asc') {
                                sorts.deliveryDate = 'desc'
                            } else {
                                delete sorts.deliveryDate
                            }
                            setSorts(sorts)
                            setWorkOrders([])
                            setDirty(!dirty)
                        }}
                        hideBarcode
                    />
                    <Table.Body>
                        {(page === -1 ? workOrders : workOrders.slice(page * 10, page * 10 + 10)).map((value, index) => {
                            return (
                                <WorkOrder
                                    key={value.workOrderId}
                                    type="table"
                                    workOrder={value}
                                    onRowClick={(state) => {
                                        const { workOrderId } = state
                                        setEditing(workOrderId)
                                    }}
                                    selectable={!!selectable}
                                    selected={!!selectedWorkOrders[value.workOrderId]}
                                    onSelected={
                                        selectable
                                            ? (workOrderId, checked, object) => {
                                                  selectedWorkOrders[workOrderId] = checked
                                                  setSelectedWorkOrders(selectedWorkOrders)
                                                  if (onChangeSelection) {
                                                      onChangeSelection(selectedWorkOrders, workOrderId, object, checked)
                                                  }
                                                  setDirtySelection(!dirtySelection)
                                              }
                                            : () => {}
                                    }
                                    noActions={!!noActions}
                                    hideBarcode
                                />
                            )
                        })}
                    </Table.Body>
                </Table>
            ) : null}

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setEditing(false)
                    setCreating(false)
                }}
            >
                <Modal.Header>{creating ? 'Creando' : 'Modificando'} riga ordine cliente</Modal.Header>
                <Modal.Content>
                    <WorkOrderEditor
                        workOrderId={editing}
                        onSave={(workOrder) => {
                            setEditing(false)
                            setCreating(false)
                            setWorkOrders([])
                            setDirty(!dirty)
                        }}
                        onCancel={() => {
                            setEditing(false)
                            setCreating(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList,
        deleteWorkOrder,
        mergeWorkOrder,
        search: { data: searchText },
    } = state

    return {
        getMachineList,
        deleteWorkOrder,
        mergeWorkOrder,
        searchText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkOrders)))
