import { Box, Center, Text } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import 'react-virtualized/styles.css'
import { Segment, Statistic } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'

function QuickLook({ network, table, fields, machinestatusfield, machinestatusmap }) {
    const [datapoints, setDatapoints] = useState([])

    useEffect(() => {
        const id = setInterval(() => {
            network
                .doRequest({
                    action: `table/line/${table}`,
                    method: network.GET,
                    data: {
                        id: '0',
                        sub_id: '0',
                        limit: '1',
                    },
                    service: network.HADES,
                    token: network.MACHINES,
                })
                .then((data) => {
                    setDatapoints(data)
                })
        }, 3000)

        return () => clearInterval(id)
    }, [table])

    const firstDatapoint = datapoints[0] ? datapoints[0] : {}

    const segments = Object.keys(fields).map((value, index) => {
        return (
            <Segment key={`quicklook-datapoint-${table}-${index}`} basic>
                <Statistic size="tiny">
                    {typeof firstDatapoint[value] === 'boolean' && (
                        <Statistic.Value>
                            {firstDatapoint[value] && <Box m="auto" w="16" h="16" bg="green.500" rounded="lg"></Box>}
                            {!firstDatapoint[value] && <Box m="auto" w="16" h="16" bg="red.500" rounded="lg"></Box>}
                        </Statistic.Value>
                    )}
                    {typeof firstDatapoint[value] !== 'boolean' && (
                        <Statistic.Value>
                            {firstDatapoint[value] !== undefined && firstDatapoint[value] !== null && firstDatapoint[value] !== ''
                                ? firstDatapoint[value]
                                : '--'}
                        </Statistic.Value>
                    )}
                    <Statistic.Label>{fields[value]}</Statistic.Label>
                </Statistic>
            </Segment>
        )
    })

    const groups = []
    let curgroup = []
    for (let i = 0; i < segments.length; i++) {
        if (i % 4 === 0 && curgroup.length > 0) {
            groups.push(<Segment.Group horizontal>{curgroup}</Segment.Group>)
            curgroup = []
        }

        curgroup.push(segments[i])
    }

    let machineStatusText = null
    if (machinestatusfield && machinestatusmap && firstDatapoint[machinestatusfield]) {
        const status = firstDatapoint[machinestatusfield]
        machineStatusText = machinestatusmap[status]
    }

    return (
        <>
            {groups}

            {machineStatusText && (
                <Center borderTop="1px" borderBottom="1px" mt="2" mb="2">
                    <Box textAlign="center" fontWeight="semibold" as="h1" lineHeight="tight" isTruncated>
                        Stato macchina:{' '}
                        <Text fontSize="4xl" color={machineStatusText.color}>
                            {machineStatusText.label}
                        </Text>
                    </Box>
                </Center>
            )}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(QuickLook)))
