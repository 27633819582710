import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Modal, Popup, Table } from 'semantic-ui-react'
import { baseViewDispatch, initializePage } from '../lib/util'
function TaskDashboard(props) {
    const { network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        task: null,
    })

    const [jobs, setJobs] = useState([])
    const [dirty, setDirty] = useState(false)

    useEffect(() => {
        network.getTaskList().then((data) => {
            // console.log(data)

            const jobs = []
            for (const key in data.data) {
                jobs.push(data.data[key])
            }
            setJobs(jobs)
            setDirty(false)
        })
    }, [table, dirty])

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    if (!jobs || jobs.length === 0) return null

    let headerlen = 0
    let header = []
    let seenDescriptions = {}
    for (const element of jobs) {
        if (element.length > headerlen) {
            headerlen += 1
            for (const task of element) {
                if (!seenDescriptions[task.taskDescription]) {
                    header.push(task)
                }
                seenDescriptions[task.taskDescription] = true
            }
        }
    }

    const renderJobDash = (tasklist, header) => {
        if (!tasklist || tasklist.length === 0) {
            return null
        }
        // console.log('Rendering', tasklist)
        const { jobCode, jobProgram, jobType, image, description, client, deliveryDate } = tasklist[0]

        const cells = []
        for (const head of header) {
            cells.push({})
        }

        for (const task of tasklist) {
            let added = false
            let position = 0
            for (const index in header) {
                const head = header[index]
                if (head.taskDescription === task.taskDescription) {
                    added = true
                    position = parseInt(index)
                    break
                }
            }

            if (!added) {
                cells[position] = {}
            } else {
                cells[position] = task
            }
        }

        return (
            <Table.Row>
                <Table.Cell>{deliveryDate ? moment(deliveryDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</Table.Cell>
                <Table.Cell>{jobCode}</Table.Cell>
                <Table.Cell>{description}</Table.Cell>

                {cells.map((value, index) => {
                    let content = null
                    const onButtonClick = (e) => {
                        e.stopPropagation()
                        setShowNoteEditor({
                            show: true,
                            task: value,
                        })
                    }

                    const onCellClick = () => {
                        // Save note
                        let newstatus = 0
                        switch (value.taskStatus) {
                            case 0:
                                newstatus = 2
                                break
                            case 1:
                                newstatus = 0
                                break
                            case 2:
                                newstatus = 1
                                break
                            default:
                                newstatus = value.taskStatus + 1
                        }

                        const taskobj = {
                            taskId: value.taskId,
                            taskStatus: newstatus,
                        }
                        network.updateTask(taskobj).then(() => {
                            setDirty(true)
                        })
                    }

                    if (value.taskNote) {
                        content = <Popup content={value.taskNote} trigger={<Button color="teal" icon="sticky note" onClick={onButtonClick} />} />
                    }

                    if (value.taskDescription) {
                        if (value.taskStatus === 0) {
                            return <Table.Cell onClick={onCellClick}>{content}</Table.Cell>
                        } else if (value.taskStatus === 1) {
                            return (
                                <Table.Cell className="completed-cell" onClick={onCellClick}>
                                    {content}
                                </Table.Cell>
                            )
                        } else if (value.taskStatus === 2) {
                            return (
                                <Table.Cell className="warning-cell" onClick={onCellClick}>
                                    {content}
                                </Table.Cell>
                            )
                        } else if (value.taskStatus === 3) {
                            return <Table.Cell className="empty-cell">{content}</Table.Cell>
                        } else {
                            return <Table.Cell>{content}</Table.Cell>
                        }
                    } else {
                        return <Table.Cell className="empty-cell">{content}</Table.Cell>
                    }
                })}
            </Table.Row>
        )
    }

    return (
        <>
            <Modal open={showNoteEditor.show}>
                <Modal.Header>Modifica nota per task {showNoteEditor.task ? showNoteEditor.task.taskDescription : ''}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field
                            control={Input}
                            label={<span className="label">Nota</span>}
                            name="nota"
                            value={showNoteEditor.task ? showNoteEditor.task.taskNote : ''}
                            onChange={(data) => {
                                // console.log('Input data is', data.target.value)
                                setShowNoteEditor({
                                    show: true,
                                    task: { ...showNoteEditor.task, taskNote: data.target.value },
                                })
                            }}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="red"
                        onClick={() => {
                            const taskobj = {
                                taskId: showNoteEditor.task.taskId,
                                taskNote: '',
                            }
                            network.updateTask(taskobj).then(() => {
                                setDirty(true)
                                setShowNoteEditor({ show: false, task: null })
                            })
                        }}
                    >
                        Elimina <Icon name="delete" />
                    </Button>
                    <Button
                        onClick={() => {
                            setShowNoteEditor({ show: false, task: null })
                        }}
                    >
                        Annulla <Icon name="delete" />
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            // Save note
                            const taskobj = {
                                taskId: showNoteEditor.task.taskId,
                                taskNote: showNoteEditor.task.taskNote,
                            }
                            network.updateTask(taskobj).then(() => {
                                setDirty(true)
                                setShowNoteEditor({ show: false, task: null })
                            })
                        }}
                    >
                        Conferma <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Data di completamento</Table.HeaderCell>
                        <Table.HeaderCell>Commessa</Table.HeaderCell>
                        <Table.HeaderCell>Descrizione</Table.HeaderCell>
                        {header.map((value, index) => {
                            return (
                                <Table.HeaderCell
                                    key={`task-dashboard-task-${value.taskId}`}
                                    style={{ whiteSpace: 'nowrap', minWidth: 66, maxWidth: 66, width: 66, height: 200 }}
                                >
                                    <div
                                        style={{
                                            transform: 'rotate(90deg)',
                                            transformOrigin: '0 0',
                                            width: 200,
                                            height: 66,
                                            marginLeft: 33,
                                            marginTop: -108,
                                            textAlign: 'end',
                                        }}
                                    >
                                        {value.taskDescription}
                                    </div>
                                </Table.HeaderCell>
                            )
                        })}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {jobs.map((value, index) => {
                        return renderJobDash(value, header)
                    })}
                </Table.Body>
            </Table>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TaskDashboard)))
