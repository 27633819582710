import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import MachinePicker from '../job/MachinePicker'
import SummarySheet from '../job/SummarySheet'
import { baseViewDispatch, mapOrdToOrdering, roundToDigits } from '../lib/util'
import ProductionDropdown from '../utility/ProductionDropdown'

const { app, workorderprint } = routes

class Production extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, production } = props

        this.state = {
            deleting: false,
            ...production,
            productionMinutes: production ? parseFloat(production.productionMinutes) : 0,
            inProduction: production ? !!production.machineId : false,
        }

        if (onCostChange && production) {
            onCostChange(this.totalCost(), production.productionId)
        }
    }

    totalCost() {
        let { productionPrice } = this.state
        let total = productionPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { production, productionId, network } = this.props

        if ((!production || !production.productionId) && productionId) {
            network.getProduction(productionId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let {
            hideProduction,
            hideProductionName,
            hideQuantity,
            hideDeliveryDate,
            isBalance,
            hideActions,
            hideBarcode,
            hideImage,
            onRowClick,
            sorts,
            onProductionDeliveryDateHeaderClick,
        } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideDeliveryDate && (
                            <Table.HeaderCell
                                {...(sorts ? { sorted: sorts.deliveryDate ? mapOrdToOrdering[sorts.deliveryDate] : null } : {})}
                                onClick={onProductionDeliveryDateHeaderClick ? onProductionDeliveryDateHeaderClick : () => {}}
                            >
                                Data di completamento
                            </Table.HeaderCell>
                        )}
                        {/* {!hideImage && <Table.HeaderCell>Foto</Table.HeaderCell>} */}
                        {!hideProduction && <Table.HeaderCell>Articolo</Table.HeaderCell>}
                        {!hideProductionName && <Table.HeaderCell>Ordine cliente</Table.HeaderCell>}
                        {!hideProductionName && <Table.HeaderCell>Clienti</Table.HeaderCell>}
                        {!hideProductionName && <Table.HeaderCell>Master</Table.HeaderCell>}
                        {!hideProductionName && <Table.HeaderCell>Materiali</Table.HeaderCell>}
                        {!hideQuantity && <Table.HeaderCell>Da produrre</Table.HeaderCell>}
                        {!hideQuantity && <Table.HeaderCell>Prodotto</Table.HeaderCell>}
                        {!hideQuantity && <Table.HeaderCell>Consegnato</Table.HeaderCell>}
                        {!hideQuantity && <Table.HeaderCell>Rimanente</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!hideActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    updateBarcode = () => {
        const { productionId } = this.state
        try {
            const text = `${productionId}`
            if (document.getElementById(`workorderbarcode${productionId}`)) {
                JsBarcode(`#workorderbarcode${productionId}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } else {
                setImmediate(this.updateBarcode)
            }
        } catch (e) {
            console.error(e)
        }
    }

    renderForTable() {
        let {
            hideProduction,
            hideProductionName,
            hideQuantity,
            hideDeliveryDate,
            hideBarcode,
            hideImage,
            isBalance,
            onRowClick,
            hideActions,
            network,
            success,
            error,
        } = this.props
        let {
            productionId,
            articles,
            jobs,
            customers,
            masters,
            materials,
            productQuantity,
            deliveredQuantity,
            producedQuantity,
            deliveryDate,
            articleImages,
            image: moldImage,
            inProduction,
        } = this.state

        setImmediate(this.updateBarcode)

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideDeliveryDate && <Table.Cell>{moment(deliveryDate).format('DD/MM/YYYY')}</Table.Cell>}
                    {/* {!hideImage && (
                        <Table.Cell>
                            {articleImages && articleImages.length > 0 && (
                                <div style={styles.imageContainer}>
                                    <ImageDefault fromRoot src={articleImages[0].picturePath} ui={false} style={styles.productionImage} />
                                </div>
                            )}
                            {!articleImages ||
                                (articleImages.length === 0 && (
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={moldImage} ui={false} style={styles.productionImage} />
                                    </div>
                                ))}
                        </Table.Cell>
                    )} */}
                    {!hideProduction && <Table.Cell>{articles}</Table.Cell>}
                    {!hideProductionName && <Table.Cell>{jobs}</Table.Cell>}
                    {!hideProductionName && <Table.Cell>{customers}</Table.Cell>}
                    {!hideProductionName && <Table.Cell>{masters}</Table.Cell>}
                    {!hideProductionName && <Table.Cell>{materials}</Table.Cell>}
                    {!hideQuantity && <Table.Cell>{roundToDigits(productQuantity)}</Table.Cell>}
                    {!hideQuantity && <Table.Cell>{roundToDigits(producedQuantity)}</Table.Cell>}
                    {!hideQuantity && <Table.Cell>{roundToDigits(deliveredQuantity)}</Table.Cell>}
                    {!hideQuantity && <Table.Cell>{roundToDigits(productQuantity - producedQuantity)}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`workorderbarcode${productionId}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!hideActions && this.state.productionId && (
                        <Table.Cell className="no-print">
                            {/* <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingProduction: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button> */}
                            <SummarySheet objectToSerialize={this.state} type="production" />
                            <MachinePicker
                                inProduction={inProduction}
                                onMachineChosen={(machine) => {
                                    network
                                        .getLotList({
                                            productionId,
                                        })
                                        .then(async (result) => {
                                            this.setState({ inProduction: true })

                                            try {
                                                await network.createProduction({ productionId: this.state.productionId, machineId: machine.machineId })
                                            } catch (e) {
                                                console.error('createProduction err', e);
                                            }

                                            for (const lot of result.data) {
                                                await network.createLot({ ...lot, machineId: machine.machineId })

                                                await network.createMachine({
                                                    machineId: machine.machineId,
                                                    activeLotId: lot.lotId,
                                                })

                                                await network.createWorkOrder({
                                                    workOrderId: lot.workOrderId,
                                                    inProduction: true,
                                                })
                                            }

                                            if (machine.machineHadesRef && machine.machineEndpoint) {
                                                if (machine.nodeMold) {
                                                    await network.createCommand({
                                                        node: machine.nodeMold,
                                                        action: 1,
                                                        value: `${this.state.moldCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })

                                                    // await network.createCommand({
                                                    //     node: `${this.state.moldCode}`,
                                                    //     action: 2,
                                                    //     value: `AVVIO_PRODUZIONE_GESTIONALE_${this.state.moldCode}`,
                                                    //     endpoint: 'ftp_ripi',
                                                    // })
                                                }

                                                if (machine.nodeMoldId) {
                                                    await network.createCommand({
                                                        node: machine.nodeMoldId,
                                                        action: 1,
                                                        value: `${this.state.moldCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                // if (machine.nodeCavities) {
                                                //     await network.createCommand({
                                                //         node: machine.nodeQuantity,
                                                //         action: 1,
                                                //         value: `${this.state.molds[0].piecesPerCycle ? this.state.molds[0].piecesPerCycle : 1}`,
                                                //         endpoint: machine.machineEndpoint,
                                                //     })
                                                // }

                                                if (machine.nodeJob) {
                                                    await network.createCommand({
                                                        node: machine.nodeJob,
                                                        action: 1,
                                                        value: `${jobs}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeCustomer) {
                                                    await network.createCommand({
                                                        node: machine.nodeCustomer,
                                                        action: 1,
                                                        value: `${customers ? customers : ''}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeLot) {
                                                    await network.createCommand({
                                                        node: machine.nodeLot,
                                                        action: 1,
                                                        // value: `${result.lotId}`,
                                                        value: `${this.state.productionId}`,
                                                        endpoint: machine.machineEndpoint,
                                                        datatype: 'UInt32',
                                                    })
                                                }

                                                if (machine.nodeMaterial && this.state.materials && this.state.materials.length > 0) {
                                                    await network.createCommand({
                                                        node: machine.nodeMaterial,
                                                        action: 1,
                                                        value: `${masters} ${materials}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeQuantity) {
                                                    await network.createCommand({
                                                        node: machine.nodeQuantity,
                                                        action: 1,
                                                        value: `${this.state.productQuantity - this.state.producedQuantity}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            console.error('Error while starting production')
                                        })
                                }}
                            />
                            <Button
                                icon
                                color="orange"
                                disabled={!inProduction}
                                onClick={(e) => {
                                    network
                                        .getLotList({
                                            productionId,
                                        })
                                        .then(async (result) => {
                                            this.setState({ inProduction: false })
                                            await network.createProduction({ productionId: this.state.productionId, machineId: null })

                                            for (const lot of result.data) {
                                                await network.createLot({ ...lot, machineId: null })

                                                await network.createMachine({
                                                    machineId: lot.machineId,
                                                    activeLotId: null,
                                                })

                                                await network.createWorkOrder({
                                                    workOrderId: lot.workOrderId,
                                                    inProduction: false,
                                                })
                                            }
                                        })
                                }}
                            >
                                <Icon name="stop" />
                            </Button>
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteProduction({
                                        productionId,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            {/* <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            /> */}
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, production } = this.props

        network.deleteStdProduction(production)

        this.setState({ deleting: false })
    }

    render() {
        let { mergeDestinations, mergingProduction, productionId } = this.state
        let { login, type, production, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(production)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.productionCard} {...extraprops} />
                break
        }

        return (
            <>
                <Modal
                    key={`modal_production_${productionId}`}
                    closeIcon
                    open={!!mergingProduction}
                    onClose={() => this.setState({ mergingProduction: false })}
                >
                    <Modal.Header>Modificando productione</Modal.Header>
                    <Modal.Content>
                        <ProductionDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, productions) => {
                                this.setState({
                                    mergeDestinations: productions,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergeProduction({
                                    sourceId: this.state.productionId,
                                    destinationId: this.state.mergeDestinations[0].productionId,
                                })
                                this.setState({ mergingProduction: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.productionId === this.state.mergeDestinations[0].productionId
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{'\''} ELIMINATO IL MATERIALE {'"'}{this.state.jobCode}{'"'} ({this.state.productionId})
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    productionImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    productionContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    productionCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getProduction } = state
    let rproductionId = null
    if (login && login.data && login.data.user) rproductionId = login.data.user.rproductionId

    return { role: rproductionId, getProduction, login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Production)))
