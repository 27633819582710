import { Switch } from '@chakra-ui/switch'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Label, Step } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Job } from '../components'
import { Works } from '../containers'

class FinalBalance extends Component {
    constructor(props) {
        super(props)
        let { job } = this.props.match.params

        if(job) {
            job = job.replaceAll('__slash__', '/')
        }
        
        this.state = {
            step: 0,
            job,
            search: '',
            dateRange: [moment().subtract(1, 'years').toDate(), moment().endOf('month').toDate()],
            minus: true,
            employees: [],
            machines: [],
            costs: [],
        }

        this.printRef = React.createRef()
        this.printButton = React.createRef()
    }

    componentDidMount() {
        let { network } = this.props
        let { job } = this.state
        network.getJob(job)
        this.updateMenu()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { network, getJob } = this.props
        const { job } = this.state

        if (this.state.step !== prevState.step) {
            this.updateMenu()

            if (this.state.step === 1) {
                network.getCostList(job, moment(this.state.dateRange[0]).format('YYYY-MM-DD'), moment(this.state.dateRange[1]).format('YYYY-MM-DD'))
            } else {
                this.setState({ costs: [] })
            }
        }

        if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
            if (getJob.status.success) {
                let jobInfo = getJob.data[job][0]
                this.setState(
                    {
                        jobInfo,
                        dateRange:
                            !jobInfo || !jobInfo.lastBillDate
                                ? this.state.dateRange
                                : [moment(jobInfo.lastBillDate).toDate(), this.state.dateRange[1]],
                    },
                    this.updateMenu
                )
            }
        }
    }

    updateMenu() {
        let { sub } = this.props
        if (!sub) {
            //console.log('FinalBalance didmount');
            let menuitems = [
                {
                    name: 'Da ultima fatturazione',
                    icon: null,
                    position: 'none',
                    disabled: () => {
                        let { step, jobInfo } = this.state
                        return step !== 0 || !jobInfo || !jobInfo.lastBillDate
                    },
                    action: () => {
                        let { jobInfo } = this.state

                        this.setState({
                            dateRange: [moment(jobInfo.lastBillDate).toDate(), this.state.dateRange[1]],
                        })
                    },
                },
                {
                    name: 'Fino a oggi',
                    icon: null,
                    position: 'none',
                    disabled: () => {
                        return this.state.step !== 0
                    },
                    action: () => {
                        this.setState({
                            dateRange: [this.state.dateRange[0], moment().toDate()],
                        })
                    },
                },
                {
                    name: 'Ultimo mese',
                    icon: null,
                    position: 'none',
                    disabled: () => {
                        return this.state.step !== 0
                    },
                    action: () => {
                        this.setState({
                            dateRange: [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().startOf('month').subtract(1, 'day').toDate(),
                            ],
                        })
                    },
                },
                {
                    name: 'Stampa',
                    icon: 'print',
                    position: 'right',
                    disabled: () => {
                        return this.state.step !== 1
                    },
                    action: () => {
                        document.getElementById('print-button-balance').click()
                    },
                },
            ]
            this.props.toolbarResult(menuitems)
        }
    }

    prev() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.max(0, step - 1) })
    }

    next() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.min(2, step + 1) })
    }

    clearSearch() {
        this.setState({
            search: '',
        })
        this.props.searchResult('')
    }

    render() {
        let { step, job, dateRange, works, minus } = this.state
        let { costs } = this.props

        let content = <div />

        content = [
            <Step.Group key="stepgroup">
                <Step active={step === 0} completed={step > 0}>
                    <Icon name="book" />
                    <Step.Content>
                        <Step.Title>Lavori da considerare</Step.Title>
                        <Step.Description>Quali lavori includere nel calcolo del consuntivo</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1} completed={step > 1} disabled={step < 1}>
                    <Icon name="hdd" />
                    <Step.Content>
                        <Step.Title>Risultato</Step.Title>
                        <Step.Description>Bilancio finale</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>,
            step < 1 ? (
                <View row fullw className="fullWidthDatePicker" key="stepheader">
                    <Label>Inizio:</Label>
                    <DatePicker
                        style={{ width: '100%', textAlign: 'center' }}
                        onChange={(data) => {
                            //console.log('Date change', data);
                            let { dateRange } = this.state
                            dateRange[0] = data
                            this.setState({ dateRange })
                        }}
                        selected={dateRange[0]}
                        dateFormat="dd/MM/yyyy"
                    />
                    <Label>Fine:</Label>
                    <DatePicker
                        style={{ width: '100%', textAlign: 'center' }}
                        onChange={(data) => {
                            let { dateRange } = this.state
                            dateRange[1] = data
                            this.setState({ dateRange })
                        }}
                        selected={dateRange[1]}
                        dateFormat="dd/MM/yyyy"
                    />
                    <View around>
                        <Switch
                            toggle
                            defaultChecked={minus}
                            onChange={(e) => {
                                //console.log('Checkbox value', e, data.checked);
                                this.setState({ minus: e.target.checked })
                            }}
                        >
                            Sottrai ore macchina
                        </Switch>
                    </View>
                </View>
            ) : null,
            <View column fullw fullh style={{ marginTop: 0 }} key="stepcontent">
                {
                    [
                        <View key="balance_1" around column fullw fullh style={{ height: '95%' }}>
                            <Works
                                sub
                                noActions
                                job={job}
                                dateRange={dateRange}
                                noPagination
                                searchable
                                selectable
                                invertSelection
                                minus={minus}
                                onChange={(works) => {
                                    //console.log('FinalBalance got works', works);
                                    this.setState({ works })
                                }}
                                fullh
                            />
                        </View>,
                        <View key="balance_2" column fullw fullh style={{ height: '95%' }}>
                            <ReactToPrint
                                ref={(el) => (this.printButton = el)}
                                trigger={() => (
                                    <button id="print-button-balance" style={{ display: 'none' }}>
                                        Stampa
                                    </button>
                                )}
                                content={() => {
                                    //console.log('printing content', this.printRef);
                                    return this.printRef.current
                                }}
                            />
                            <div ref={this.printRef}>
                                <Job sub jobId={job} works={works} costs={costs} noActions type="full" isBalance dateRange={this.state.dateRange} />
                            </div>
                        </View>,
                    ][step]
                }
                <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.prev()}>Indietro</Button>
                    {step < 1 && (
                        <Button color="black" onClick={() => this.next()}>
                            Avanti
                        </Button>
                    )}
                    {/* {step === 1 && (
                        <Button color="green" onClick={() => ()=>{
                            
                        }}>
                            Fine
                        </Button>
                    )} */}
                </View>
            </View>,
        ]

        return (
            <View fullw fullh column style={{ padding: 16 }}>
                {content}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getCostList: {
            data: { data: costs },
        },
        getJob,
        getItemCosts,
    } = state

    return {
        costs,
        getJob,
        getItemCosts,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(FinalBalance))
