import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input } from 'semantic-ui-react'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function JobDropdown({ single, network, onJobSelected, defaultSelectedJobs = [] }) {
    if (single && defaultSelectedJobs === []) {
        defaultSelectedJobs = null
    }

    const [options, setOptions] = useState([])
    const [defaultJobs, setDefaultJobs] = useState({})
    const [jobs, setJobs] = useState({})
    const [dirty, setDirty] = useState(false)
    const [search, setSearch] = useState('')
    const [selectedJobs, setSelectedJobs] = useState(single ? defaultSelectedJobs : [])
    const forceUpdate = useForceUpdate()

    let dropdownValue = []
    if (!single) {
        for (const job of selectedJobs) {
            dropdownValue.push(job.jobCode)
        }
    } else {
        dropdownValue = selectedJobs
    }

    useEffect(() => {
        if (!single) {
            const defaultjobobj = {}
            for (const element of defaultSelectedJobs) {
                defaultjobobj[element.jobCode] = element
            }
            setDefaultJobs(defaultjobobj)
            setSelectedJobs(defaultSelectedJobs)
        }
    }, [defaultSelectedJobs])
    useEffect(() => {
        if (single) {
            setSelectedJobs(defaultSelectedJobs)
        }
    })

    useEffect(() => {
        network.getJobList(false, false, search, true).then((data) => {
            const joblist = data.data
            const jobobj = {}

            let jobOptions = []
            for (let i in joblist) {
                let job = joblist[i]
                jobobj[job.jobCode] = job
                jobOptions.push({
                    key: job.jobCode,
                    text: `${job.jobCode} - ${job.description}`,
                    value: job.jobCode,
                })
            }

            if (!single) {
                for (let i in selectedJobs) {
                    let job = selectedJobs[i]
                    if (!jobobj[job.jobCode]) {
                        jobobj[job.jobCode] = job
                        jobOptions.push({
                            key: job.jobCode,
                            text: `${job.jobCode} - ${job.description}`,
                            value: job.jobCode,
                        })
                    }
                }
            } else {
                if (!jobobj[selectedJobs]) {
                    jobobj[selectedJobs] = { jobCode: selectedJobs, description: selectedJobs }
                    jobOptions.push({
                        key: selectedJobs,
                        text: `${selectedJobs}`,
                        value: selectedJobs,
                    })
                }
            }

            for (let i in defaultJobs) {
                let job = defaultJobs[i]
                if (!jobobj[job.jobCode]) {
                    jobobj[job.jobCode] = job
                    jobOptions.push({
                        key: job.jobCode,
                        text: `${job.jobCode} - ${job.description}`,
                        value: job.jobCode,
                    })
                }
            }

            setJobs(jobobj)
            setOptions(jobOptions)
            setDirty(false)
        })
    }, [dirty, search, defaultJobs])

    const extraProps = {}
    if (!single) {
        extraProps.multiple = true
    }

    // console.log('Dropdown value', dropdownValue)

    return (
        <View column fullw>
            <Dropdown
                key={dropdownValue}
                {...extraProps}
                onChange={(ev, data) => {
                    if (onJobSelected) {
                        if (!single) {
                            const selectedList = []
                            for (const key of data.value) {
                                if (jobs[key]) {
                                    selectedList.push(jobs[key])
                                } else {
                                    if (defaultJobs[key]) {
                                        selectedList.push(defaultJobs[key])
                                    }
                                }
                            }

                            setSelectedJobs(selectedList)
                            onJobSelected(ev, data, data.value, selectedList)
                        } else {
                            const selectedList = data.value

                            setSelectedJobs(selectedList)
                            onJobSelected(ev, data, data.value, selectedList)
                        }
                    }
                }}
                placeholder="Ordine cliente"
                fluid
                search
                selection
                options={options}
                onSearchChange={(event, data) => {
                    setSearch(data.searchQuery)
                }}
                value={dropdownValue}
            />
            <>
                {selectedJobs && selectedJobs.map
                    ? selectedJobs.map((value, index) => {
                          if (!value) {
                              return null
                          }
                          return (
                              <Form.Group key={`job-dropdown-${value.jobCode}`} widths="equal">
                                  <Form.Field
                                      id={`form-input-control-job-cycle-${index}`}
                                      control={Input}
                                      label={`Tempo ciclo ${value.jobCode}`}
                                      name="cycleTime"
                                      type="number"
                                      step="0.01"
                                      value={value.cycleTime}
                                      onChange={(e) => {
                                          const jobToEdit = selectedJobs[index]
                                          jobToEdit.cycleTime = e.target.value
                                          // console.log('New cycleTime value', e.target.value)
                                          forceUpdate()
                                      }}
                                      placeholder=""
                                  />
                                  <Form.Field
                                      id={`form-input-control-job-pieces-${index}`}
                                      control={Input}
                                      label={`Pezzi per ciclo ${value.jobCode}`}
                                      name="piecesPerCycle"
                                      type="number"
                                      value={value.piecesPerCycle}
                                      onChange={(e) => {
                                          const jobToEdit = selectedJobs[index]
                                          jobToEdit.piecesPerCycle = e.target.value
                                          // console.log('New pieces value', e.target.value)
                                          forceUpdate()
                                      }}
                                      placeholder=""
                                  />
                              </Form.Group>
                          )
                      })
                    : null}
            </>
        </View>
    )
}

export default withRouter(connectNetwork(JobDropdown))
