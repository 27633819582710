import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import MaterialEditor from '../job/MaterialEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Material from './Material'
function Materials(props) {
    const { network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult, deleteMaterial, mergeMaterial, sub, login, searchable, search } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        material: null,
    })

    const [materials, setMaterials] = useState([])
    const [count, setCount] = useState(0)
    const [dirty, setDirty] = useState(false)
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState(false)
    const [filters, setFilters] = useState({})
    const [sorts, setSorts] = useState({})
    const [page, setPage] = useState(0)
    const pageRef = useRef()
    const countRef = useRef()

    const items = 10

    pageRef.current = page
    countRef.current = count

    useEffect(() => {
        network.getMaterialList(search, filters, sorts, page, 10).then((data) => {
            // console.log(data)

            const materials = []
            for (const key in data.data) {
                materials.push(data.data[key])
            }
            setCount(data.count)
            setMaterials(materials)
            setDirty(false)
        })
    }, [dirty, deleteMaterial.fetching, mergeMaterial.fetching, page])

    useEffect(() => {
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo materiale',
                icon: 'add',
                action: () => {
                    setCreating(true)
                },
            })
            // extraTools.push({
            //     name: 'Importa stampi da file',
            //     icon: 'check',
            //     action: () => {
            //         this.setState({
            //             showImport: true,
            //         })
            //     },
            // })
        }

        const toolbar = sub
            ? []
            : [
                  ...extraTools,
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: function () {
                          if (pageRef.current > 0) {
                              setPage(pageRef.current - 1)
                          }
                      },
                  },
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: function () {
                          if (pageRef.current * 10 + 10 < countRef.current) {
                              setPage(pageRef.current + 1)
                          }
                      },
                  },
              ]

        const sortoptions = {
            materialCode: {
                name: 'materialCode',
                label: 'Codice materiale',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMaterials([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMaterials([])
                    setDirty(true)
                },
            },
            creationDate: {
                name: 'creationDate',
                label: 'Data di creazione',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMaterials([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMaterials([])
                    setDirty(true)
                },
            },
        }

        const filteroptions = {
            materialCode: {
                control: Input,
                name: 'materialCode',
                input: 'text',
                placeholder: 'Codice materiale',
                label: 'Codice materiale',
                onChange: (e) => {
                    filters.materialCode = { value: e.target.value, key: 'materialCode', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setMaterials([])
                    setDirty(true)
                },
            },
            materialName: {
                control: Input,
                name: 'materialName',
                input: 'text',
                placeholder: 'Nome materiale',
                label: 'Nome materiale',
                onChange: (e) => {
                    filters.materialName = { value: e.target.value, key: 'materialName', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setMaterials([])
                    setDirty(true)
                },
            },
        }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [])

    if (!materials || materials.length === 0) return null

    // console.log('Materials are', materials)
    return (
        <>
            <Box ml={16} mt={4}>
                Visualizzando {page * items} - {page * items + items} su {count}
            </Box>
            <Table celled sortable>
                <Material
                    type="header"
                    sorts={sorts}
                    onMaterialCodeHeaderClick={() => {
                        if (!sorts.materialCode) {
                            sorts.materialCode = 'asc'
                        } else if (sorts.materialCode === 'asc') {
                            sorts.materialCode = 'desc'
                        } else {
                            delete sorts.materialCode
                        }
                        setSorts(sorts)
                        setMaterials([])
                        setDirty(true)
                    }}
                />
                <Table.Body>
                    {materials.map((value, index) => {
                        return (
                            <Material
                                key={value.materialId}
                                type="table"
                                material={value}
                                onRowClick={(state) => {
                                    const { materialId } = state
                                    setEditing(materialId)
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setEditing(false)
                    setCreating(false)
                }}
            >
                <Modal.Header>{creating ? 'Creando materiale' : 'Modificando materiale'}</Modal.Header>
                <Modal.Content>
                    <MaterialEditor
                        materialId={creating ? null : editing}
                        onSave={(material) => {
                            setEditing(false)
                            setCreating(false)
                            setMaterials([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                            setCreating(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMaterial, mergeMaterial, login, search } = state

    return {
        getMachineList,
        deleteMaterial,
        mergeMaterial,
        login,
        search
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Materials)))
