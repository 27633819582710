import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import MasterEditor from '../job/MasterEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Master from './Master'
function Masters(props) {
    const { network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult, deleteMaster, mergeMaster, sub, login } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        master: null,
    })

    const [masters, setMasters] = useState([])
    const [count, setCount] = useState(0)
    const [dirty, setDirty] = useState(false)
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState(false)
    const [filters, setFilters] = useState({})
    const [sorts, setSorts] = useState({})
    const [page, setPage] = useState(0)
    const pageRef = useRef()
    const countRef = useRef()

    const items = 10

    pageRef.current = page
    countRef.current = count

    useEffect(() => {
        network.getMasterList(undefined, filters, sorts, page, 10).then((data) => {
            const masters = []
            for (const key in data.data) {
                masters.push(data.data[key])
            }
            setCount(data.count)
            setMasters(masters)
            setDirty(false)
        })
    }, [dirty, deleteMaster.fetching, mergeMaster.fetching, page])

    useEffect(() => {
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo master',
                icon: 'add',
                action: () => {
                    setCreating(true)
                },
            })
            // extraTools.push({
            //     name: 'Importa stampi da file',
            //     icon: 'check',
            //     action: () => {
            //         this.setState({
            //             showImport: true,
            //         })
            //     },
            // })
        }

        const toolbar = sub
            ? []
            : [
                  ...extraTools,
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: function () {
                          if (pageRef.current > 0) {
                              setPage(pageRef.current - 1)
                          }
                      },
                  },
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: function () {
                          if (pageRef.current * 10 + 10 < countRef.current) {
                              setPage(pageRef.current + 1)
                          }
                      },
                  },
              ]

        const sortoptions = {
            masterCode: {
                name: 'masterCode',
                label: 'Codice master',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMasters([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMasters([])
                    setDirty(true)
                },
            },
            masterSupplier: {
                name: 'masterSupplier',
                label: 'Fornitore',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMasters([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMasters([])
                    setDirty(true)
                },
            },
            creationDate: {
                name: 'creationDate',
                label: 'Data di creazione',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMasters([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMasters([])
                    setDirty(true)
                },
            },
        }

        const filteroptions = {
            masterCode: {
                control: Input,
                name: 'masterCode',
                input: 'text',
                placeholder: 'Codice master',
                label: 'Codice master',
                onChange: (e) => {
                    filters.masterCode = { value: e.target.value, key: 'masterCode', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setMasters([])
                    setDirty(true)
                },
            },
            masterName: {
                control: Input,
                name: 'masterName',
                input: 'text',
                placeholder: 'Nome master',
                label: 'Nome master',
                onChange: (e) => {
                    filters.masterName = { value: e.target.value, key: 'masterName', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setMasters([])
                    setDirty(true)
                },
            },
            masterSupplier: {
                control: Input,
                name: 'masterSupplier',
                input: 'text',
                placeholder: 'Fornitore',
                label: 'Fornitore',
                onChange: (e) => {
                    filters.masterName = { value: e.target.value, key: 'masterName', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setMasters([])
                    setDirty(true)
                },
            },
        }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [])

    if (!masters || masters.length === 0) return null

    return (
        <>
            <Box ml={16} mt={4}>
                Visualizzando {page * items} - {page * items + items} su {count}
            </Box>
            <Table celled sortable>
                <Master
                    type="header"
                    sorts={sorts}
                    onMasterCodeHeaderClick={() => {
                        if (!sorts.masterCode) {
                            sorts.masterCode = 'asc'
                        } else if (sorts.masterCode === 'asc') {
                            sorts.masterCode = 'desc'
                        } else {
                            delete sorts.masterCode
                        }
                        setSorts(sorts)
                        setMasters([])
                        setDirty(true)
                    }}
                    onMasterSupplierHeaderClick={() => {
                        if (!sorts.masterSupplier) {
                            sorts.masterSupplier = 'asc'
                        } else if (sorts.masterSupplier === 'asc') {
                            sorts.masterSupplier = 'desc'
                        } else {
                            delete sorts.masterSupplier
                        }
                        setSorts(sorts)
                        setMasters([])
                        setDirty(true)
                    }}
                />
                <Table.Body>
                    {masters.map((value, index) => {
                        return (
                            <Master
                                key={value.masterId}
                                type="table"
                                master={value}
                                onRowClick={(state) => {
                                    const { masterId } = state
                                    setEditing(masterId)
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setEditing(false)
                    setCreating(false)
                }}
            >
                <Modal.Header>{creating ? 'Creando master' : 'Modificando master'}</Modal.Header>
                <Modal.Content>
                    <MasterEditor
                        masterId={creating ? null : editing}
                        onSave={(master) => {
                            setEditing(false)
                            setCreating(false)
                            setMasters([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                            setCreating(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMaster, mergeMaster, login } = state

    return {
        getMachineList,
        deleteMaster,
        mergeMaster,
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Masters)))
