import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Confirm, Dropdown, Grid, Icon } from 'semantic-ui-react'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Numpad } from '../components'

// const dgram = require('chrome-dgram')
// const udp = dgram.createSocket('udp4')

// udp.on('message', (msg, rinfo) => {
// 	console.log('Got message "' + msg.toString() + '" from ' + rinfo.address)
// 	udp.close()
// })

const COLORS = { quantity: '#00B75B', qMax: '#EAFAF1', scrap: '#FFD930', sMax: '#FEF9E7' }
let MACHINE_COLORS = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown'].sort(
    () => Math.random() - 0.5
)

const reasons = [
    {
        id: 0,
        label: 'Setup',
    },
    {
        id: 1,
        label: 'Produzione',
    },
    {
        id: 2,
        label: 'Blocco',
    },
    {
        id: 3,
        label: 'Pausa',
    },
]
const REASON_COLORS = ['yellow', 'green', 'red', 'orange']

class TerminalView extends Component {
    constructor(props) {
        super(props)
        let {
            network: { getCookie },
        } = props
        this.state = {
            machine: getCookie('terminal_machine'),
            value: 1,
            activeReason: undefined,
            job: undefined,
            work: undefined,
            productQuantity: undefined,
            radialQuantity: [
                {
                    name: 'Massimo',
                    value: 500,
                    fill: COLORS.qMax,
                },
                {
                    name: 'Quantità',
                    value: 0,
                    fill: COLORS.quantity,
                },
            ],
            radialScrap: [
                {
                    name: 'Massimo',
                    value: 500,
                    fill: COLORS.sMax,
                },
                {
                    name: 'Scarti',
                    value: 0,
                    fill: COLORS.scrap,
                },
            ],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { job, work } = this.state
        let {
            realtime,
            network,
            createJob: {
                status: { success: cjSuccess },
                fetching: cjFetching,
            },
        } = this.props
        let {
            realtime: prealtime,
            createJob: { fetching: pcjFetching },
        } = prevProps

        if (prealtime !== realtime) {
            network.socketReq('synopticLastData', { id: job, sub_id: work }, (response) => {
                // console.log('ack', response)
                if (!response) {
                    this.updateValues({})
                } else {
                    this.updateValues(response)
                }
            })
        }

        if (pcjFetching === true && cjFetching === false && cjSuccess === true) {
            network.getRecentJobList(0, 20, '', undefined, true, false)
        }
    }

    componentDidMount() {
        let { network } = this.props

        // network.getJobList(page, items, search.data, isEmployee, createdExternal);
        network.getRecentJobList(0, 20, '', undefined, true, false)
        // network.getJobList(true, true)
        network.getMachineList('', true)

        /*
		if (!sub) {
			this.props.toolbarResult([]);
		}*/
    }

    updateSocket = (radialQuantity, radialScrap, activeReason) => {
        let { job, machine, work } = this.state
        let { worker } = this.props
        this.setState({ radialQuantity, radialScrap, activeReason })

        if (job === undefined) return
        if (machine === undefined) return
        if (activeReason === undefined) return

        let timestamp = moment()
        let data = {
            '': [
                {
                    timestamp,
                    id: job,
                    sub_id: `${work}`,
                    terminal: machine.machineIdentifier.toString(),
                    operator: worker.identifier.toString(),
                    quantity: radialQuantity[1].value,
                    scrap: radialScrap[1].value,
                    reason: activeReason,
                },
            ],
            _worker: [
                {
                    timestamp,
                    id: worker.identifier.toString(),
                    sub_id: job,
                    terminal: machine.machineIdentifier.toString(),
                    work_code: `${work}`,
                    quantity: radialQuantity[1].value,
                    scrap: radialScrap[1].value,
                    reason: activeReason,
                },
            ],
            _machine: [
                {
                    timestamp,
                    id: machine.machineIdentifier.toString(),
                    sub_id: job,
                    operator: worker.identifier.toString(),
                    work_code: `${work}`,
                    quantity: radialQuantity[1].value,
                    scrap: radialScrap[1].value,
                    reason: activeReason,
                },
            ],
        }

        // console.log('sending', data, activeReason)
        this.props.network.socketSend('synopticDataTesting', 'synoptic-01', data)
    }

    updateValues = ({ quantity = 0, scrap = 0, reason = undefined }) => {
        if (reason === undefined) return

        let { radialQuantity, radialScrap, activeReason } = this.state
        radialQuantity[1] = {
            ...radialQuantity[1],
            value: quantity,
        }
        radialScrap[1] = {
            ...radialScrap[1],
            value: scrap,
        }
        activeReason = reason
        // console.log('updateValues', quantity, scrap, reason)
        this.setState({ radialQuantity, radialScrap, activeReason })
    }

    render() {
        let { radialQuantity, radialScrap, activeReason, value, job, work, productQuantity, machine } = this.state
        let { jobs = [], machines, network } = this.props

        let options = []
        let optionsWork = {}
        for (let job of jobs) {
            if (!(job.jobCode in optionsWork)) optionsWork[job.jobCode] = []
            if (job.workCode) {
                optionsWork[job.jobCode].push({
                    key: job.workCode,
                    text: `${job.workCode}`,
                    value: job.workCode,
                })
            } else {
                if (optionsWork[job.jobCode].filter((e) => e.key === 0).length <= 0) {
                    optionsWork[job.jobCode].push({
                        key: 0,
                        text: `0`,
                        value: 0,
                    })
                }
            }
            if (options.filter((e) => e.key === job.jobCode).length > 0) continue

            let desctext = ''
            if (job.description && job.description !== '') {
                desctext = ` - ${job.description}`
            }

            options.push({
                key: job.jobCode,
                text: `${job.jobCode}${desctext}` + (job.urgencyLevel > 0 ? ' [URGENTE]' : ''),
                // text: `${job.jobCode}: ${job.description}`,
                value: job.jobCode,
            })
        }
        if (machine === undefined) {
            return machines ? (
                <View fullw fullh>
                    <Grid columns={Math.min(machines.length, 4)} stretched container padded={false}>
                        {machines.map((value, index) => (
                            <Grid.Column key={index}>
                                <Button
                                    color={MACHINE_COLORS[index % MACHINE_COLORS.length]}
                                    style={{ height: '100%', width: '100%', fontSize: 'xx-large' }}
                                    onClick={() => {
                                        network.setCookie('terminal_machine', value)
                                        this.setState({ machine: value })
                                    }}
                                >
                                    {value.machineName}
                                </Button>
                            </Grid.Column>
                        ))}
                    </Grid>
                </View>
            ) : null
        }
        return (
            <View column fullw fullh style={{ paddingLeft: 16 }}>
                {/* header */}
                <View row fullw style={{ height: '30%' }}>
                    {/* select object */}
                    <View style={{ width: '29%', justifyContent: 'space-around' }}>
                        <div style={{ alignSelf: 'center' }}>
                            <Dropdown
                                placeholder="Scegli commessa"
                                compact
                                selection
                                options={options}
                                onChange={(e, { value }) => {
                                    this.setState({ job: value, work: undefined, productQuantity: undefined })
                                }}
                            />
                        </div>
                        {job !== undefined && (
                            <div style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    placeholder="Scegli ord. lavoro"
                                    compact
                                    selection
                                    value={work || ''}
                                    options={optionsWork[job]}
                                    onChange={(e, { value }) => {
                                        // console.log('search', value, job)
                                        let j = jobs.filter((x) => x.jobCode === job)
                                        let order = j.filter((x) => x.workCode === value)[0]
                                        // console.log('on ordine di lavoro', j)
                                        let { productQuantity } = order || {}
                                        this.setState({ work: value, productQuantity })
                                        this.props.network.socketReq(
                                            'synopticLastData',
                                            {
                                                id: job,
                                                sub_id: `${value}`,
                                            },
                                            (response) => {
                                                // console.log(response)
                                                if (!response) {
                                                    this.updateValues({})
                                                } else {
                                                    this.updateValues(response)
                                                }
                                            }
                                        )
                                    }}
                                />
                            </div>
                        )}
                    </View>

                    {/* charts */}
                    <View style={{ width: '70%' }}>
                        <View fullh fullw style={{ marginTop: 30, justifyContent: 'space-around' }}>
                            <Box alignItems="center" color="blue" size="huge">
                                <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>{productQuantity || '--'}</Box>
                                <Box fontSize="lg" textAlign={['center', 'center']}>da fare</Box>
                            </Box>
                            <Box alignItems="center" color="green" size="huge">
                                <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>{radialQuantity[1].value}</Box>
                                <Box fontSize="lg" textAlign={['center', 'center']}>corretti</Box>
                            </Box>
                            <Box alignItems="center" color="yellow" size="huge">
                                <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>{radialScrap[1].value}</Box>
                                <Box fontSize="lg" textAlign={['center', 'center']}>errati</Box>
                            </Box>
                        </View>
                    </View>
                </View>

                {/* Body */}
                <View row fullw style={{ height: '69%' }}>
                    {/* left cell */}
                    <View column between style={{ width: '69%' }}>
                        {/* add remove */}
                        <View fullw between style={{ height: '29%' }}>
                            <Button
                                disabled={work === undefined}
                                icon
                                color="green"
                                labelPosition="left"
                                onClick={() => {
                                    radialQuantity[1] = {
                                        ...radialQuantity[1],
                                        value: radialQuantity[1].value + value,
                                    }
                                    this.updateSocket(radialQuantity, radialScrap, activeReason)
                                }}
                            >
                                <Icon name="add circle" />
                                <b>{value}pz.</b> Corretti
                            </Button>
                            <Button
                                disabled={work === undefined}
                                icon
                                color="red"
                                labelPosition="left"
                                onClick={() => {
                                    radialQuantity[1] = {
                                        ...radialQuantity[1],
                                        value: radialQuantity[1].value - value,
                                    }
                                    this.updateSocket(radialQuantity, radialScrap, activeReason)
                                }}
                            >
                                <b>{value}pz.</b> Corretti
                                <Icon name="minus circle" />
                            </Button>
                            <Button
                                disabled={work === undefined}
                                icon
                                color="yellow"
                                labelPosition="left"
                                onClick={() => {
                                    radialScrap[1] = {
                                        ...radialScrap[1],
                                        value: radialScrap[1].value + value,
                                    }
                                    this.updateSocket(radialQuantity, radialScrap, activeReason)
                                }}
                            >
                                <Icon name="add circle" />
                                <b>{value}pz.</b> Scarti
                            </Button>
                            <Button
                                disabled={work === undefined}
                                icon
                                color="red"
                                labelPosition="left"
                                onClick={() => {
                                    radialScrap[1] = {
                                        ...radialScrap[1],
                                        value: radialScrap[1].value - value,
                                    }
                                    this.updateSocket(radialQuantity, radialScrap, activeReason)
                                }}
                            >
                                <b>{value}pz.</b> Scarti
                                <Icon name="minus circle" />
                            </Button>
                        </View>

                        {/* reason */}
                        <View fullw style={{ height: '50%' }}>
                            <Button.Group fluid>
                                {reasons.map((value, index) => (
                                    <Button
                                        disabled={work === undefined}
                                        key={index}
                                        color={activeReason === index ? REASON_COLORS[index] : null}
                                        onClick={() => {
                                            if (activeReason === 0 && radialQuantity[1].value === 0 && radialScrap[1].value === 0) {
                                                // Going from setup to another phase and nothing was added, show dialog
                                                this.setState({
                                                    confirmToSetup: true,
                                                    waitForConfirm: index,
                                                })
                                                return
                                            }

                                            activeReason = index
                                            this.updateSocket(radialQuantity, radialScrap, activeReason)
                                        }}
                                    >
                                        {value.label}
                                    </Button>
                                ))}

                                <Button
                                    disabled={work === undefined}
                                    color={activeReason === 4 ? 'red' : null}
                                    onClick={() => this.setState({ terminateDialog: true })}
                                >
                                    Termina
                                </Button>
                                <Confirm
                                    open={this.state.terminateDialog}
                                    content="Sicuro di voler terminare la commessa?"
                                    cancelButton="Annulla"
                                    confirmButton="Conferma conclusione commessa"
                                    onCancel={() => this.setState({ terminateDialog: false })}
                                    onConfirm={() => {
                                        activeReason = 4
                                        this.setState({ terminateDialog: false, job: undefined })
                                        this.props.network.createJob(
                                            job,
                                            {
                                                active: 0,
                                            },
                                            true
                                        )
                                    }}
                                />
                                <Confirm
                                    open={this.state.confirmToSetup}
                                    content="Non e&#39; stato inserito o scartato nessuno pezzo. Vuoi davvero passare alla prossima fase?"
                                    cancelButton="Annulla"
                                    confirmButton="Continua"
                                    onCancel={() => this.setState({ confirmToSetup: false })}
                                    onConfirm={() => {
                                        let { waitForConfirm } = this.state
                                        activeReason = waitForConfirm
                                        this.setState({ confirmToSetup: false })
                                        this.updateSocket(radialQuantity, radialScrap, activeReason)
                                    }}
                                />
                            </Button.Group>
                        </View>
                    </View>
                    {/* right cell */}
                    <View style={{ width: '30%' }}>
                        <Numpad
                            label={<span className="label">Incremento</span>}
                            value={this.state.value}
                            onChange={(value) => {
                                this.setState({ value })
                            }}
                        />
                    </View>
                </View>
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        workday: {
            data: { date: workday },
        },
        loginEmployee: {
            data: { user: worker },
        },
        getRecentJobList: {
            data: { data: joblist },
        },
        realtime: { data: realtime },
        createJob,
        getMachineList: {
            data: { data: machines },
        },
    } = state

    let jobs = []
    for (let job in joblist) {
        jobs.push(joblist[job][0])
    }

    jobs.sort((a, b) => {
        return b.urgencyLevel - a.urgencyLevel
    })

    // console.log('jobs', jobs)
    return {
        workday,
        worker,
        jobs,
        realtime,
        createJob,
        machines,
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TerminalView)))
