import { Box, Stack } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button } from 'semantic-ui-react'
import { Default } from '../lib/util'
import Filter from '../utility/Filter'

class FilterBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activatedFilters: {},
            showFilters: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ activatedFilters: {}, showFilters: false })
        }
    }

    render() {
        const { showFilters, activatedFilters } = this.state
        const { filters } = this.props
        return (
            <View column style={{ position: 'relative' }}>
                {Object.keys(filters).length > 0 && (
                    <Box p={6} style={!showFilters ? { display: 'none' } : {}}>
                        <Default>
                            <View
                                row
                                style={{
                                    minWidth: '100%',
                                    height: 74,
                                    marginTop: 4,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    overflowY: 'visible',
                                }}
                            >
                                <Stack direction="row">
                                    {Object.keys(filters).map((value, index) => {
                                        const activeKeys = Object.keys(activatedFilters)

                                        if (index < activeKeys.length) {
                                            return (
                                                <Box w="300px" h="74px">
                                                    <Filter
                                                        key={index}
                                                        activeFilter={activeKeys[index]}
                                                        availableFilters={filters}
                                                        toExclude={activatedFilters}
                                                        onFilterActivated={(key, value) => {
                                                            const { activatedFilters } = this.state

                                                            // console.log('Activated filters onFilterActivated', key, value)
                                                            if (value) {
                                                                activatedFilters[key] = value
                                                            } else {
                                                                delete activatedFilters[key]
                                                            }

                                                            this.setState({
                                                                activatedFilters,
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            )
                                        } else if (index === activeKeys.length) {
                                            return (
                                                <Box w="300px" h="74px">
                                                    <Filter
                                                        activeFilter={null}
                                                        key={index}
                                                        availableFilters={filters}
                                                        toExclude={activatedFilters}
                                                        onFilterActivated={(key, value) => {
                                                            const { activatedFilters } = this.state

                                                            // console.log('Activated filters onFilterActivated', key, value)
                                                            if (value) {
                                                                activatedFilters[key] = value
                                                            } else {
                                                                delete activatedFilters[key]
                                                            }

                                                            this.setState({
                                                                activatedFilters,
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                            )
                                        } else return null
                                    })}
                                </Stack>
                            </View>
                        </Default>
                        {/* <Divider /> */}
                    </Box>
                )}
                <Button
                    basic
                    style={{ zIndex: 999, position: 'absolute', top: 0, left: 0 }}
                    onClick={() => {
                        let { showFilters } = this.state

                        showFilters = !showFilters
                        this.setState({ showFilters })
                    }}
                    icon="filter"
                />
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = (state) => {
    let { filters } = state

    return {
        filters,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(FilterBar)))
