import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input } from 'semantic-ui-react'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function ArticleDropdown({ single, network, onArticleSelected, defaultSelectedArticles = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [defaultArticles, setDefaultArticles] = useState({})
    const [articles, setArticles] = useState({})
    const [dirty, setDirty] = useState(false)
    const [search, setSearch] = useState('')
    const [selectedArticles, setSelectedArticles] = useState([])
    const forceUpdate = useForceUpdate()

    let dropdownValue = []
    if (!single) {
        for (const article of selectedArticles) {
            dropdownValue.push(article.articleId)
        }
    } else {
        dropdownValue = selectedArticles ? selectedArticles.articleId : 0
        dropdownValue = defaultSelectedArticles ? defaultSelectedArticles.articleId : dropdownValue
    }

    useEffect(() => {
        if (!single) {
            const defaultarticleobj = {}
            for (const element of defaultSelectedArticles) {
                defaultarticleobj[element.articleId] = element
            }
            setDefaultArticles(defaultarticleobj)
            setSelectedArticles(defaultSelectedArticles)
        }
    }, [defaultSelectedArticles])

    useEffect(() => {
        if (single) {
            if (defaultSelectedArticles && defaultSelectedArticles.articleId !== selectedArticles.articleId) {
                setSelectedArticles(defaultSelectedArticles)
            }
        }
    }, [defaultSelectedArticles])

    useEffect(() => {
        network.getSimpleArticleList(search).then((data) => {
            const articlelist = data.data
            const articleobj = {}

            let articleOptions = []
            for (let i in articlelist) {
                let article = articlelist[i]
                articleobj[article.articleId] = article
                articleOptions.push({
                    key: article.articleId,
                    text: `${article.articleId} - ${article.articleName} (${article.articleCode})`,
                    value: article.articleId,
                })
            }

            for (let i in selectedArticles) {
                let article = selectedArticles[i]
                if (!articleobj[article.articleId]) {
                    articleobj[article.articleId] = article
                    articleOptions.push({
                        key: article.articleId,
                        text: `${article.articleId} - ${article.articleName} (${article.articleCode})`,
                        value: article.articleId,
                    })
                }
            }

            for (let i in defaultArticles) {
                let article = defaultArticles[i]
                if (!articleobj[article.articleId]) {
                    articleobj[article.articleId] = article
                    articleOptions.push({
                        key: article.articleId,
                        text: `${article.articleId} - ${article.articleName} (${article.articleCode})`,
                        value: article.articleId,
                    })
                }
            }

            setArticles(articleobj)
            setOptions(articleOptions)
            setDirty(false)
        })
    }, [dirty, search, defaultArticles])

    const extraProps = {}
    if (!single) {
        extraProps.multiple = true
    }

    return (
        <View column fullw>
            <Dropdown
                {...extraProps}
                onChange={(ev, data) => {
                    if (onArticleSelected) {
                        if (!single) {
                            const selectedList = []
                            for (const key of data.value) {
                                if (articles[key]) {
                                    selectedList.push(articles[key])
                                } else {
                                    if (defaultArticles[key]) {
                                        selectedList.push(defaultArticles[key])
                                    }
                                }
                            }

                            for (const key in selectedList) {
                                const article = selectedList[key]
                                if (!article.quantity) {
                                    article.quantity = 80 * 18
                                }
                                if (!article.deliveryDate) {
                                    article.deliveryDate = new Date()
                                }
                                if (!article.pallets) {
                                    article.pallets = 1
                                }
                            }
                            setSelectedArticles(selectedList)
                            onArticleSelected(ev, data, data.value, selectedList)
                        } else {
                            const selectedList = articles[data.value]

                            const article = selectedList
                            if (!article.quantity) {
                                article.quantity = 80 * 18
                            }
                            if (!article.deliveryDate) {
                                article.deliveryDate = new Date()
                            }
                            if (!article.pallets) {
                                article.pallets = 1
                            }
                            setSelectedArticles(selectedList)
                            onArticleSelected(ev, data, data.value, selectedList)
                        }
                    }
                }}
                placeholder="Articolo"
                fluid
                search
                selection
                options={options}
                onSearchChange={(event, data) => {
                    setSearch(data.searchQuery)
                }}
                value={dropdownValue}
            />
            <>
                {!single && selectedArticles && selectedArticles.map
                    ? selectedArticles.map((value, index) => {
                          if (!value) {
                              return null
                          }
                          return (
                              <Form.Group widths="equal" key={`ArticleDropdown_${value.articleCode}_${index}`}>
                                  <Form.Field
                                      id={`form-input-control-article-qty-${index}`}
                                      control={Input}
                                      label={`Quantità da produrre ${value.articleCode}`}
                                      name="quantity"
                                      type="number"
                                      step="1"
                                      value={value.quantity}
                                      onChange={(e) => {
                                          const articleToEdit = selectedArticles[index]
                                          articleToEdit.quantity = e.target.value
                                          forceUpdate()
                                      }}
                                      placeholder=""
                                  />
                                  <Form.Field
                                      id={`form-input-control-article-delivery-${value.articleCode}_${index}`}
                                      control={DatePicker}
                                      label={<span className="label">Data di completamento</span>}
                                      name="deliveryDate"
                                      value={value.deliveryDate}
                                      dateFormat="dd/MM/yyyy"
                                      selected={value.deliveryDate}
                                      onChange={(data) => {
                                          const articleToEdit = selectedArticles[index]
                                          articleToEdit.deliveryDate = data
                                          articleToEdit.deliveryWeek = moment(data).get('week')
                                          forceUpdate()
                                      }}
                                  />
                              </Form.Group>
                          )
                      })
                    : null}
                {single && selectedArticles && !!selectedArticles.articleId && !onlySelection ? (
                    <Form style={{ marginTop: 16 }}>
                        <Form.Group widths="equal" key={`ArticleDropdown_${selectedArticles.articleId}`}>
                            <Form.Field
                                id={`form-input-control-article-qty-${selectedArticles.articleId}`}
                                control={Input}
                                label={`Pallet da produrre ${selectedArticles.articleCode}`}
                                name="pallets"
                                type="number"
                                step="1"
                                value={selectedArticles.pallets}
                                onChange={(e, data) => {
                                    const articleToEdit = selectedArticles
                                    articleToEdit.quantity = e.target.value * 80 * 18
                                    articleToEdit.pallets = e.target.value
                                    setSelectedArticles(articleToEdit)
                                    onArticleSelected(e, data, articleToEdit.articleId, articleToEdit)
                                }}
                                placeholder=""
                            />
                            <Form.Field
                                id={`form-input-control-article-qty-${selectedArticles.articleId}`}
                                control={Input}
                                label={`Quantità da produrre ${selectedArticles.articleCode}`}
                                name="quantity"
                                type="number"
                                step="1"
                                value={selectedArticles.quantity}
                                onChange={(e, data) => {
                                    const articleToEdit = selectedArticles
                                    articleToEdit.quantity = e.target.value
                                    setSelectedArticles(articleToEdit)
                                    onArticleSelected(e, data, articleToEdit.articleId, articleToEdit)
                                }}
                                placeholder=""
                            />
                            <Form.Field
                                id={`form-input-control-article-delivery-${selectedArticles.articleId}`}
                                control={DatePicker}
                                label={<span className="label">Data di completamento</span>}
                                name="deliveryDate"
                                value={selectedArticles.deliveryDate}
                                dateFormat="dd/MM/yyyy"
                                selected={selectedArticles.deliveryDate}
                                onChange={(data) => {
                                    const articleToEdit = selectedArticles
                                    articleToEdit.deliveryDate = data
                                    articleToEdit.deliveryWeek = moment(data).get('week')
                                    setSelectedArticles(articleToEdit)
                                    onArticleSelected(null, data, articleToEdit.articleId, articleToEdit)
                                    forceUpdate()
                                }}
                            />
                        </Form.Group>
                    </Form>
                ) : null}
            </>
        </View>
    )
}

export default withRouter(connectNetwork(ArticleDropdown))
