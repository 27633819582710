export function createCommand(data, isEmployee) {
    let { createCommandRequest, createCommandError, createCommandResult } = this.props

    delete data.creationDate
    delete data.updateDate

    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'create_command',
            this.createBundle(
                'create_command',
                { command: data },
                this.POST,
                createCommandRequest,
                (result) => {
                    createCommandResult(result)
                    resolve(result)
                },
                (error) => {
                    createCommandError(error)
                    reject(error)
                },
                !!isEmployee
            )
        )
    })
}
