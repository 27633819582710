import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Menu, Statistic } from 'semantic-ui-react'
import { WORKDAY_ACTIONS } from '../actions/workday'
import { get_section, routes, route_from } from '../lib/routes'
import { Default, Mobile } from '../lib/util'
const {
    app,
    organizations,
    message,
    jobs,
    employees,
    machines,
    categories,
    corps,
    deposits,
    contracts,
    admin,
    employee,
    insertion,
    calendarView,
    dashboard,
    stock,
    products,
    documents,
    dataexport,
    profile,
    collections,
    families,
    variants,
    articles,
    assignvariants,
    duplicates,
    ordering,
    offices,
    tempJobs,
    works,
    roles,
    warehouse,
    dataimport,
    monitor,
    gt2000,
    saldatrice,
    babypressa,
    terminal,
    pdfexport,
    moldmapping,
    tasks,
    clientOrders,
    negribossia,
    negribossib,
    negribossic,
    negribossid,
    oeenegribossia,
    oeenegribossib,
    oeenegribossic,
    oeenegribossid,
    wittman,
    realtimedata,
    taskdashboard,
    taskdetail,
    scheduleEditor,
    calendarEditor,
    badgescan,
    badge,
    masters,
    materials,
    packages,
    kits,
    machine1,
    oeemachine1,
    molds,
    materialcheck,
    customers,
    printjobs,
    printjobsdetail,
    ind40visualizer,
    productions,
    printjobshistory,
} = routes

const estegomenu = [
    {
        key: collections,
        label: 'Collezioni',
        icon: 'object group',
        show: (user) => {
            return !!user.info_collections
        },
    },
    {
        key: families,
        label: 'Famiglie',
        icon: 'folder open',
        show: (user) => {
            return !!user.info_families
        },
    },
    {
        key: articles,
        label: 'Articoli',
        icon: 'images',
        show: (user) => {
            return !!user.info_articles
        },
    },
    {
        key: variants,
        label: 'Varianti',
        icon: 'file image',
        show: (user) => {
            return !!user.info_variants
        },
    },
    {
        key: assignvariants,
        label: 'Associazione varianti',
        icon: 'object group outline',
        show: (user) => {
            return !!user.info_variants
        },
    },
    {
        key: duplicates,
        label: 'Associazione duplicati',
        icon: 'copy',
        show: (user) => {
            return true
        },
    },
    {
        key: ordering,
        label: 'Ordinamento',
        icon: 'ordered list',
        show: (user) => {
            return !!user.manage_ordering
        },
    },
    {
        key: admin,
        label: 'Utenti',
        icon: 'user outline',
        show: (user) => {
            return !!user.info_user
        },
    },
    {
        key: offices,
        label: 'Punti vendita',
        icon: 'map',
        show: (user) => {
            return !!user.info_office
        },
    },
]

const workerSidebar = [
    {
        key: insertion,
        label: 'Inserimento ore lavoro',
        icon: 'tasks',
        show: (user) => {
            return true
        },
    },
    {
        key: calendarView,
        label: 'Calendario',
        icon: 'calendar alternate outline',
        show: (user) => {
            return true
        },
    },
    {
        key: materialcheck,
        label: 'Controllo materiale',
        icon: 'checkmark',
        show: (user) => {
            return true
        },
    },
    // {
    //     key: terminal,
    //     label: 'Produzione in tempo reale',
    //     icon: 'chart area',
    //     show: (user) => {
    //         return true
    //     },
    // },
    {
        key: moldmapping,
        label: 'Mappatura interna',
        icon: 'sitemap',
        show: (user) => {
            return true
        },
    },
]

const vfmenu = [
    {
        key: jobs,
        label: 'Commesse',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobs,
        label: 'Ordini Produzione',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobsdetail,
        label: 'Articoli da stampare (ordini attivi)',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobshistory,
        label: 'Storico stampaggio',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: productions,
        label: 'Produzioni',
        icon: 'play',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: tempJobs,
        label: 'Commesse da ricontrollare',
        icon: 'tag',
        show: (user) => {
            return !!user.info_temp_job
        },
    },
    {
        key: moldmapping,
        label: 'Mappatura interna',
        icon: 'sitemap',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: works,
        label: 'Lavori non assegnati',
        icon: 'hourglass outline',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: calendarView,
        label: 'Calendario',
        icon: 'calendar alternate outline',
        show: (user) => {
            return true
        },
    },
    {
        label: 'Attività',
        icon: 'check',
        show: (user) => {
            return true
        },
        menu: [
            {
                key: taskdashboard,
                label: 'Dashboard',
                icon: 'table',
                show: (user) => {
                    return true
                },
            },
            {
                key: taskdetail,
                label: 'Configurazione',
                icon: 'cog',
                show: (user) => {
                    return true
                },
            },
            {
                key: tasks,
                label: 'Tasklist',
                icon: 'check',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    {
        label: 'Smarcatempo',
        icon: 'check',
        show: (user) => {
            return true
        },
        menu: [
            {
                key: badgescan,
                label: 'Scansioni Badge',
                icon: 'barcode',
                show: (user) => {
                    return true
                },
            },
            {
                key: badge,
                label: 'Gestione Badge',
                icon: 'id badge',
                show: (user) => {
                    return true
                },
            },
            {
                key: scheduleEditor,
                label: 'Editor periodi',
                icon: 'redo',
                show: (user) => {
                    return true
                },
            },
            {
                key: calendarEditor,
                label: 'Editor turni',
                icon: 'calendar alternate outline',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    {
        label: 'Produzione',
        icon: 'lab',
        show: () => true,
        menu: [
            {
                key: molds,
                label: 'Stampi',
                icon: 'tablet alternate',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: articles,
                label: 'Articoli',
                icon: 'images',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: masters,
                label: 'Master',
                icon: 'cube',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: materials,
                label: 'Materiali',
                icon: 'cubes',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: packages,
                label: 'Imballi',
                icon: 'box',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: kits,
                label: 'Kit',
                icon: 'object group',
                show: (user) => {
                    return !!user.info_articles
                },
            },
        ],
    },
    {
        label: 'Anagrafiche',
        icon: 'address card',
        show: () => true,
        menu: [
            {
                key: customers,
                label: 'Clienti',
                icon: 'images',
                show: (user) => {
                    return !!user.info_job
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
            {
                key: machines,
                label: 'Macchine',
                icon: 'hdd outline',
                show: (user) => {
                    return !!user.info_machine
                },
            },
            {
                key: stock,
                label: 'Magazzino',
                icon: 'warehouse',
                show: (user) => {
                    return !!user.info_stock
                },
            },
        ],
    },
    {
        label: 'Dati',
        icon: 'chart area',
        show: (user) => {
            return false
        },
        menu: [
            {
                key: realtimedata,
                label: 'Dashboard',
                icon: 'chart line',
                show: (user) => {
                    return true
                },
            },
            {
                key: monitor,
                label: 'C.B. Ferrari',
                icon: 'chart line',
                show: (user) => {
                    return true
                },
            },
            {
                key: gt2000,
                label: 'C.B. Ferrari GT2000 Logs',
                icon: 'chart line',
                show: (user) => {
                    return true
                },
            },
            {
                key: saldatrice,
                label: 'SWA300',
                icon: 'chart line',
                show: (user) => {
                    return true
                },
            },
            {
                key: babypressa,
                label: 'Baby Pressa',
                icon: 'chart line',
                show: (user) => {
                    return true
                },
            },
            {
                key: negribossia,
                label: 'Negribossi 1',
                icon: 'chart bar',
                show: (user) => {
                    return true
                },
            },
            {
                key: negribossib,
                label: 'Negribossi 2',
                icon: 'chart bar',
                show: (user) => {
                    return true
                },
            },
            {
                key: wittman,
                label: 'Wittman',
                icon: 'chart bar',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    {
        label: 'Altro',
        icon: 'box',
        show: () => true,
        menu: [
            {
                key: dataimport,
                label: 'Importazione dati',
                icon: 'upload',
                show: (user) => {
                    return !!user.data_import
                },
            },
            {
                key: pdfexport,
                label: 'Esportazione PDF',
                icon: 'download',
                show: (user) => {
                    return true
                },
            },
            {
                key: ind40visualizer,
                label: 'Industria 4.0',
                icon: 'table',
                show: (user) => {
                    return true
                },
            },
            // {
            //     key: negribossia,
            //     label: 'Negribossi 1 dati',
            //     icon: 'table',
            //     show: (user) => {
            //         return true
            //     },
            // },
            // {
            //     key: negribossib,
            //     label: 'Negribossi 2 dati',
            //     icon: 'cog',
            //     show: (user) => {
            //         return true
            //     },
            // },
            {
                key: oeenegribossia,
                label: 'NB220 Capannone 1',
                icon: 'cog',
                show: (user) => {
                    return true
                },
            },
            {
                key: oeenegribossib,
                label: 'ELE130 Capannone 1',
                icon: 'cog',
                show: (user) => {
                    return true
                },
            },
            {
                key: oeenegribossic,
                label: 'NB220 Capannone 2',
                icon: 'cog',
                show: (user) => {
                    return true
                },
            },
            {
                key: oeenegribossid,
                label: 'ELE130 Capannone 2',
                icon: 'cog',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    {
        label: 'Gestione utenti',
        icon: 'user outline',
        show: (user) => {
            return !!user.info_user
        },
        menu: [
            {
                key: admin,
                label: 'Utenti',
                icon: 'user outline',
                show: (user) => {
                    return !!user.info_user
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
        ],
    },
]

const krilamenu = [
    {
        key: printjobs,
        label: 'Ordini Produzione',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: jobs,
        label: 'Ordini',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobsdetail,
        label: 'Articoli da stampare (ordini aperti)',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobshistory,
        label: 'Storico stampaggio',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: productions,
        label: 'Produzioni',
        icon: 'play',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        label: 'Magazzino',
        icon: 'warehouse',
        show: () => true,
        menu: [
            {
                key: stock,
                label: 'Giacenze',
                icon: 'warehouse',
                show: (user) => {
                    return !!user.info_stock
                },
            },
            {
                key: documents,
                label: 'Movimentazione',
                icon: 'book',
                show: (user) => {
                    return !!user.info_document
                },
            },
            {
                key: deposits,
                label: 'Depositi',
                icon: 'home',
                show: (user) => {
                    return !!user.info_deposit
                },
            },
            {
                key: categories,
                label: 'Categorie',
                icon: 'picture',
                show: (user) => {
                    return !!user.info_category
                },
            },
            {
                key: corps,
                label: 'Tipologie',
                icon: 'object group outline',
                show: (user) => {
                    return !!user.info_corp
                },
            },
        ],
    },
    {
        key: works,
        label: 'Lavori non assegnati',
        icon: 'hourglass outline',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: calendarView,
        label: 'Calendario',
        icon: 'calendar alternate outline',
        show: (user) => {
            return true
        },
    },
    {
        label: 'Magazzino',
        icon: 'warehouse',
        show: () => true,
        menu: [
            {
                key: stock,
                label: 'Giacenze',
                icon: 'warehouse',
                show: (user) => {
                    return !!user.info_stock
                },
            },
            {
                key: documents,
                label: 'Movimentazione',
                icon: 'book',
                show: (user) => {
                    return !!user.info_document
                },
            },
            {
                key: deposits,
                label: 'Depositi',
                icon: 'home',
                show: (user) => {
                    return !!user.info_deposit
                },
            },
            {
                key: categories,
                label: 'Categorie',
                icon: 'picture',
                show: (user) => {
                    return !!user.info_category
                },
            },
            {
                key: corps,
                label: 'Tipologie',
                icon: 'object group outline',
                show: (user) => {
                    return !!user.info_corp
                },
            },
        ],
    },
    {
        label: 'Dati',
        icon: 'chart area',
        show: (user) => {
            return true
        },
        menu: [
            {
                key: ind40visualizer,
                label: 'Macchinari',
                icon: 'industry',
                show: (user) => {
                    return true
                },
            },
            {
                key: oeemachine1,
                label: 'OEE Macchina 1',
                icon: 'chart bar',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    {
        label: 'Produzione',
        icon: 'lab',
        show: () => true,
        menu: [
            {
                key: molds,
                label: 'Stampi',
                icon: 'tablet alternate',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: articles,
                label: 'Articoli',
                icon: 'images',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: masters,
                label: 'Master',
                icon: 'cube',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: materials,
                label: 'Materiali',
                icon: 'cubes',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: packages,
                label: 'Imballi',
                icon: 'box',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: kits,
                label: 'Kit',
                icon: 'object group',
                show: (user) => {
                    return !!user.info_articles
                },
            },
        ],
    },
    {
        label: 'Anagrafiche',
        icon: 'address card',
        show: () => true,
        menu: [
            {
                key: customers,
                label: 'Clienti',
                icon: 'images',
                show: (user) => {
                    return !!user.info_job
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
            {
                key: machines,
                label: 'Macchine',
                icon: 'hdd outline',
                show: (user) => {
                    return !!user.info_machine
                },
            },
            // {
            //     key: stock,
            //     label: 'Magazzino',
            //     icon: 'warehouse',
            //     show: (user) => {
            //         return !!user.info_stock
            //     },
            // },
        ],
    },
    {
        label: 'Gestione utenti',
        icon: 'user outline',
        show: (user) => {
            return !!user.info_user
        },
        menu: [
            {
                key: admin,
                label: 'Utenti',
                icon: 'user outline',
                show: (user) => {
                    return !!user.info_user
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
        ],
    },
]

const normalmenu = [
    {
        key: routes[process.env.REACT_APP_DEFAULT_ROUTE],
        label: 'Home',
        icon: 'home',
        show: (user) => {
            return true
        },
    },
    {
        label: 'Commesse',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
        menu: [
            {
                key: jobs,
                label: 'Commesse',
                icon: 'tasks',
                show: (user) => {
                    return !!user.info_job
                },
            },
            {
                key: works,
                label: 'Lavori non assegnati',
                icon: 'hourglass outline',
                show: (user) => {
                    return !!user.info_job
                },
            },
            {
                key: tempJobs,
                label: 'Commesse da ricontrollare',
                icon: 'tag',
                show: (user) => {
                    return !!user.info_temp_job
                },
            },
        ],
    },
    {
        label: 'Grafici',
        icon: 'chart bar',
        show: (user) => {
            return !!user.dashboard
        },
        menu: [
            {
                key: dashboard,
                label: 'Dashboard',
                icon: 'chart bar',
                show: (user) => {
                    return !!user.dashboard
                },
            },
            {
                key: jobs,
                label: 'Commesse',
                icon: 'tasks',
                show: (user) => {
                    return !!user.info_job
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
            {
                key: machines,
                label: 'Macchine',
                icon: 'hdd outline',
                show: (user) => {
                    return !!user.info_machine
                },
            },
        ],
    },
    {
        label: 'Magazzino',
        icon: 'warehouse',
        show: () => true,
        menu: [
            {
                key: warehouse,
                label: 'Magazzino elettronico',
                icon: 'warehouse',
                show: (user) => {
                    return !!user.info_stock
                },
            },
            {
                key: stock,
                label: 'Magazzino',
                icon: 'warehouse',
                show: (user) => {
                    return !!user.info_stock
                },
            },
            {
                key: documents,
                label: 'Documenti',
                icon: 'book',
                show: (user) => {
                    return !!user.info_document
                },
            },
        ],
    },
    {
        label: 'Vetrina',
        icon: 'grid layout',
        show: () => true,
        menu: [
            {
                key: collections,
                label: 'Collezioni',
                icon: 'object group',
                show: (user) => {
                    return !!user.info_collections
                },
            },
            {
                key: families,
                label: 'Famiglie',
                icon: 'folder open',
                show: (user) => {
                    return !!user.info_families
                },
            },
            {
                key: articles,
                label: 'Articoli',
                icon: 'images',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: variants,
                label: 'Varianti',
                icon: 'file image',
                show: (user) => {
                    return !!user.info_variants
                },
            },
            {
                key: assignvariants,
                label: 'Associa',
                icon: 'object group outline',
                show: (user) => {
                    return !!user.info_variants
                },
            },
            {
                key: duplicates,
                label: 'Duplicati',
                icon: 'copy',
                show: (user) => {
                    return true
                },
            },
            {
                key: ordering,
                label: 'Ordina',
                icon: 'ordered list',
                show: (user) => {
                    return !!user.manage_ordering
                },
            },
        ],
    },
    {
        label: 'Anagrafiche',
        icon: 'address card',
        show: () => true,
        menu: [
            {
                key: offices,
                label: 'Punti vendita',
                icon: 'map',
                show: (user) => {
                    return !!user.info_office
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
            {
                key: machines,
                label: 'Macchine',
                icon: 'hdd outline',
                show: (user) => {
                    return !!user.info_machine
                },
            },
            {
                key: deposits,
                label: 'Depositi',
                icon: 'home',
                show: (user) => {
                    return !!user.info_deposit
                },
            },
            {
                key: products,
                label: 'Articoli',
                icon: 'shopping cart',
                show: (user) => {
                    return !!user.info_product
                },
            },
            {
                key: categories,
                label: 'Categorie',
                icon: 'picture',
                show: (user) => {
                    return !!user.info_category
                },
            },
            {
                key: corps,
                label: 'Tipologie',
                icon: 'object group outline',
                show: (user) => {
                    return !!user.info_corp
                },
            },
        ],
    },
    {
        label: 'Network',
        icon: 'connectdevelop',
        show: (user) => {
            return !!user.dashboard
        },
        menu: [
            {
                key: organizations,
                label: 'Rubrica',
                icon: 'address book outline',
                show: (user) => {
                    return !!user.info_organizations
                },
            },
            {
                key: contracts,
                label: 'Appalti',
                icon: 'file alternate outline',
                show: (user) => {
                    return !!user.info_contract
                },
            },
            {
                key: message,
                label: 'Messaggi',
                icon: 'mail outline',
                show: (user) => {
                    return !!user.info_message
                },
            },
            {
                key: route_from(profile, '/test_company'),
                label: 'Profilo',
                icon: 'user circle',
                show: (user) => {
                    return !!user.info_profile
                },
            },
        ],
    },
    {
        label: 'Gestione utenti',
        icon: 'user outline',
        show: (user) => {
            return !!user.info_user
        },
        menu: [
            {
                key: admin,
                label: 'Utenti',
                icon: 'user outline',
                show: (user) => {
                    return !!user.info_user
                },
            },
            {
                key: roles,
                label: 'Ruoli e Permessi',
                icon: 'lock',
                show: (user) => {
                    return !!user.info_roles
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
            {
                key: machines,
                label: 'Macchine',
                icon: 'hdd outline',
                show: (user) => {
                    return !!user.info_machine
                },
            },
        ],
    },
    {
        label: 'Altro',
        icon: 'box',
        show: () => true,
        menu: [
            {
                key: dataexport,
                label: 'Esportazione dati',
                icon: 'upload',
                show: (user) => {
                    return !!user.data_export
                },
            },
            {
                key: dataimport,
                label: 'Importazione dati',
                icon: 'upload',
                show: (user) => {
                    return !!user.data_import
                },
            },
            {
                key: 'download_pdf',
                label: 'Modulo DA',
                icon: 'cloud download',
                show: (user) => {
                    return !!user.download_pdf
                },
                action: () => window.open(process.env.PUBLIC_URL + '/RichiestaDigitalArtisan.pdf'),
            },
        ],
    },
    /*
	{
		key: turns,
		label: 'Turni di lavoro',
		icon: 'time',
		show: (user) => {
			return !!user.settings
		}
	},
	{
		key: settings,
		label: 'Blackbox e license',
		icon: 'cogs',
		show: (user) => {
			return !!user.settings
		}
	},
	{
		key: help,
		label: 'Help',
		icon: 'question circle outline',
		show: (user) => {
			return !!user.help
		}
	},
	{
		key: about,
		label: 'Informazioni',
		icon: 'info',
		show: (user) => {
			return !!user.about
		}
	},
	*/
]

const gastreghinimenu = [
    {
        key: jobs,
        label: 'Commesse',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobs,
        label: 'Ordini produzione',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: printjobsdetail,
        label: 'Articoli da produrre (ordini attivi)',
        icon: 'tasks',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: productions,
        label: 'Produzioni',
        icon: 'play',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: tempJobs,
        label: 'Commesse da ricontrollare',
        icon: 'tag',
        show: (user) => {
            return !!user.info_temp_job
        },
    },
    // {
    //     key: moldmapping,
    //     label: 'Mappatura interna',
    //     icon: 'sitemap',
    //     show: (user) => {
    //         return !!user.info_job
    //     },
    // },
    {
        key: works,
        label: 'Lavori non assegnati',
        icon: 'hourglass outline',
        show: (user) => {
            return !!user.info_job
        },
    },
    {
        key: calendarView,
        label: 'Calendario',
        icon: 'calendar alternate outline',
        show: (user) => {
            return true
        },
    },
    {
        label: 'Attività',
        icon: 'check',
        show: (user) => {
            return true
        },
        menu: [
            {
                key: taskdashboard,
                label: 'Dashboard',
                icon: 'table',
                show: (user) => {
                    return true
                },
            },
            {
                key: taskdetail,
                label: 'Configurazione',
                icon: 'cog',
                show: (user) => {
                    return true
                },
            },
            {
                key: tasks,
                label: 'Tasklist',
                icon: 'check',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    // {
    //     label: 'Smarcatempo',
    //     icon: 'check',
    //     show: (user) => {
    //         return true
    //     },
    //     menu: [
    //         {
    //             key: badgescan,
    //             label: 'Scansioni Badge',
    //             icon: 'barcode',
    //             show: (user) => {
    //                 return true
    //             },
    //         },
    //         {
    //             key: badge,
    //             label: 'Gestione Badge',
    //             icon: 'id badge',
    //             show: (user) => {
    //                 return true
    //             },
    //         },
    //         {
    //             key: scheduleEditor,
    //             label: 'Editor periodi',
    //             icon: 'redo',
    //             show: (user) => {
    //                 return true
    //             },
    //         },
    //         {
    //             key: calendarEditor,
    //             label: 'Editor turni',
    //             icon: 'calendar alternate outline',
    //             show: (user) => {
    //                 return true
    //             },
    //         },
    //     ],
    // },
    {
        label: 'Produzione',
        icon: 'lab',
        show: () => true,
        menu: [
            // {
            //     key: molds,
            //     label: 'Stampi',
            //     icon: 'tablet alternate',
            //     show: (user) => {
            //         return !!user.info_articles
            //     },
            // },
            {
                key: articles,
                label: 'Articoli',
                icon: 'images',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            // {
            //     key: masters,
            //     label: 'Master',
            //     icon: 'cube',
            //     show: (user) => {
            //         return !!user.info_articles
            //     },
            // },
            {
                key: materials,
                label: 'Materiali',
                icon: 'cubes',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            {
                key: packages,
                label: 'Imballi',
                icon: 'box',
                show: (user) => {
                    return !!user.info_articles
                },
            },
            // {
            //     key: kits,
            //     label: 'Kit',
            //     icon: 'object group',
            //     show: (user) => {
            //         return !!user.info_articles
            //     },
            // },
        ],
    },
    {
        label: 'Anagrafiche',
        icon: 'address card',
        show: () => true,
        menu: [
            {
                key: customers,
                label: 'Clienti',
                icon: 'images',
                show: (user) => {
                    return !!user.info_job
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
            {
                key: machines,
                label: 'Macchine',
                icon: 'hdd outline',
                show: (user) => {
                    return !!user.info_machine
                },
            },
            {
                key: stock,
                label: 'Magazzino',
                icon: 'warehouse',
                show: (user) => {
                    return !!user.info_stock
                },
            },
        ],
    },
    {
        label: 'Altro',
        icon: 'box',
        show: () => true,
        menu: [
            {
                key: ind40visualizer,
                label: 'Industria 4.0',
                icon: 'table',
                show: (user) => {
                    return true
                },
            },
        ],
    },
    {
        label: 'Gestione utenti',
        icon: 'user outline',
        show: (user) => {
            return !!user.info_user
        },
        menu: [
            {
                key: admin,
                label: 'Utenti',
                icon: 'user outline',
                show: (user) => {
                    return !!user.info_user
                },
            },
            {
                key: employees,
                label: 'Impiegati',
                icon: 'address card outline',
                show: (user) => {
                    return !!user.info_worker
                },
            },
        ],
    },
]

let menu = []

switch (process.env.REACT_APP_BRANDING) {
    default:
    case 'gastreghini':
        menu = gastreghinimenu
        break
    case 'vfstampi':
        menu = vfmenu
        break
    case 'krila':
        menu = krilamenu
        break
}

const workermenu = workerSidebar

class SideBar extends Component {
    constructor(props) {
        super(props)
        let {
            location: { pathname },
        } = this.props.history
        let { history, isEmployee, isDateSidebar, login, workday } = this.props

        //set profile page
        let { data = {} } = login
        let { user = {} } = data
        let { companies = [] } = user
        let organizationId = companies[0]

        if (organizationId) {
            let profileIndex = menu.findIndex((x) => x.key === profile)
            if (profileIndex >= 0) menu[profileIndex].key = route_from(profile, `${organizationId}`)
        }

        this.state = {
            sidemenu: get_section(pathname),
            weekStart: moment().subtract(7, 'days'),
            weekEnd: moment(),
            selected: {},
            hideSidebar: false,
            menuToShow: props.isEmployee ? workermenu : menu,
        }

        if (isDateSidebar) {
            let m = workday.data.date
            let value = {
                date: m,
                color: m.day() === 0 || m.day() === 6 ? 'red' : 'grey',
                key: m.format('YYYY-MM-DD'),
            }
            this.props.workdayResult(value)
            this.state.sidemenu = value.key
            this.state.selected = value
        }

        if (this.checkPath(pathname) && isDateSidebar) {
            this.state.hideSidebar = true
        }

        this.unlisten = history.listen((location) => {
            let { pathname } = location
            if (this.checkPath(pathname) && isDateSidebar) {
                this.setState({
                    hideSidebar: true,
                })
            } else {
                this.setState({
                    hideSidebar: false,
                })
            }
        })
    }

    checkPath(pathname) {
        return pathname === route_from(employee, insertion, calendarView) || pathname === route_from(employee, calendarView)
    }

    nextWeek() {
        this.setState({
            weekStart: this.state.weekStart.add(1, 'w'),
            weekEnd: this.state.weekEnd.add(1, 'w'),
        })
    }

    prevWeek() {
        this.setState({
            weekStart: this.state.weekStart.subtract(1, 'w'),
            weekEnd: this.state.weekEnd.subtract(1, 'w'),
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { workday } = this.props

        if (workday !== prevProps.workday) {
            this.setState({
                weekStart: moment(workday.data.date).startOf('week'),
                weekEnd: moment(workday.data.date).endOf('week'),
                selected: moment(workday.data.date).format('YYYY-MM-DD'),
                sidemenu: workday.data.key,
            })
        }
    }

    renderMenu(_menu, submenu) {
        let { sidemenu, hideSidebar } = this.state
        let { history, isEmployee, isDateSidebar, login } = this.props
        const MenuContainer = submenu ? Menu.Menu : Menu
        if (isDateSidebar) {
            let days = []
            for (var m = moment(this.state.weekStart); m.isBefore(this.state.weekEnd); m.add(1, 'days')) {
                days.push({
                    date: moment(m),
                    color: m.day() === 0 || m.day() === 6 ? 'red' : 'grey',
                    key: moment(m).format('YYYY-MM-DD'),
                    show: () => true,
                })
            }
            _menu = days
        }

        if (hideSidebar) {
            return null
        }

        let user = null
        if (!isEmployee && login && (login.fetching || !login.authenticated)) {
            return null
        } else if (login && login.authenticated) {
            user = login.data.user
        }

        let extraMenuProps = {}

        if (submenu) {
            extraMenuProps = {}
        } else {
            extraMenuProps = {}
        }

        return (
            <React.Fragment>
                <Default>
                    <MenuContainer
                        {...extraMenuProps}
                        compact
                        className="noScrollBars sidebarContainer"
                        icon="labeled"
                        vertical
                        borderless
                        style={
                            !submenu
                                ? {
                                      zIndex: 45,
                                      borderRadius: 'unset',
                                      border: 'unset',
                                      boxShadow: 'unset',
                                      maxHeight: '98%',
                                      overflowY: 'auto',
                                      overflowX: 'hidden',
                                      minWidth: 92,
                                  }
                                : {}
                        }
                    >
                        {!submenu && !isDateSidebar && <Menu.Item style={{ height: 100 }} />}
                        {!submenu && isDateSidebar && (
                            <View row fullw around>
                                <Button
                                    onClick={() => {
                                        this.prevWeek()
                                    }}
                                >
                                    <Icon name="arrow left" />
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.nextWeek()
                                    }}
                                >
                                    <Icon name="arrow right" />
                                </Button>
                            </View>
                        )}
                        {_menu.map((value, index) => {
                            let onClick = () => {}
                            if (value.key) {
                                onClick = () => {
                                    if (value.action) return value.action()

                                    this.setState({ sidemenu: value.key, selected: value })
                                    if (!isEmployee && !isDateSidebar) {
                                        history.push(route_from(app, value.key))
                                    } else if (isDateSidebar) {
                                        this.props.workdayResult(value)
                                    } else {
                                        history.push(route_from(employee, value.key))
                                    }
                                }
                            }

                            return value.show(user) ? (
                                <Menu.Item
                                    key={index}
                                    style={
                                        !submenu
                                            ? {
                                                  padding: '8px',
                                                  borderBottom: '1px solid black',
                                              }
                                            : { marginLeft: 6 }
                                    }
                                    name={`${value.key}`}
                                    className={`${value.key}`}
                                    active={value.key === sidemenu}
                                    onClick={onClick}
                                >
                                    {isDateSidebar && (
                                        <Statistic color={value.color}>
                                            <Statistic.Value>{value.date.format('DD')}</Statistic.Value>
                                            <Statistic.Label>
                                                {value.date.format('MM')}/{value.date.format('YYYY')}
                                            </Statistic.Label>
                                        </Statistic>
                                    )}
                                    {!isDateSidebar && !submenu && (
                                        <React.Fragment>
                                            <Icon name={value.icon} />
                                            {value.label}
                                        </React.Fragment>
                                    )}
                                    {!isDateSidebar && submenu && (
                                        <View fullw row>
                                            <Icon name={value.icon} />
                                            {value.label}
                                        </View>
                                    )}
                                    {value.menu ? this.renderMenu(value.menu, true) : null}
                                </Menu.Item>
                            ) : null
                        })}
                    </MenuContainer>
                </Default>

                <Mobile>
                    {_menu.map((value, index) => {
                        if (!value.show(user)) return null

                        let onClick = () => {}
                        if (value.key) {
                            onClick = () => {
                                if (value.action) return value.action()

                                this.setState({ sidemenu: value.key, selected: value })
                                if (!isDateSidebar) {
                                    history.push(route_from(app, value.key))
                                } else {
                                    this.props.workdayResult(value)
                                }
                            }
                        }

                        if (value.menu) return this.renderMenu(value.menu)

                        return (
                            <Menu.Item key={index} name={`${value.key}`} active={value.key === sidemenu} onClick={onClick}>
                                {isDateSidebar && (
                                    <Statistic color={value.color}>
                                        <Statistic.Value>{value.date.format('DD')}</Statistic.Value>
                                        <Statistic.Label>
                                            {value.date.format('MM')}/{value.date.format('YYYY')}
                                        </Statistic.Label>
                                    </Statistic>
                                )}
                                {!isDateSidebar && (
                                    <React.Fragment>
                                        <Icon name={value.icon} />
                                        {value.label}
                                    </React.Fragment>
                                )}
                            </Menu.Item>
                        )
                    })}
                </Mobile>
            </React.Fragment>
        )
    }

    renderStandardVersion() {
        return (
            <span
                style={{
                    zIndex: 100,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    fontFamily: 'monospace',
                    background: '#000000',
                    color: '#FFD90F',
                    fontWeight: 'bold',
                    width: '110px',
                    textAlign: 'center',
                }}
            >
                {`${process.env.REACT_APP_VERSION}`}
            </span>
        )
    }

    renderVfVersion() {
        return (
            <span
                style={{
                    zIndex: 100,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    fontFamily: 'monospace',
                    background: '#014493',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    width: '110px',
                    textAlign: 'center',
                }}
            >
                {`${process.env.REACT_APP_VERSION}`}
            </span>
        )
    }

    render() {
        let { isEmployee, isDateSidebar } = this.props
        let _menu = this.state.menuToShow
        return (
            <React.Fragment>
                <Default>
                    {this.renderMenu(_menu)}
                    {process.env.REACT_APP_BRANDING === 'syncode' || process.env.REACT_APP_BRANDING === 'krila' || process.env.REACT_APP_BRANDING === 'gastreghini' ? this.renderStandardVersion() : null}
                    {process.env.REACT_APP_BRANDING === 'vfstampi' ? this.renderVfVersion() : null}
                </Default>
                <Mobile>
                    <Menu
                        icon="labeled"
                        style={{
                            zIndex: 45,
                            borderRadius: 'unset',
                            border: 'unset',
                            boxShadow: 'unset',
                            maxWidth: '100%',
                            overflowY: 'hidden',
                            overflowX: 'auto',
                        }}
                    >
                        {isDateSidebar && (
                            <View column around>
                                <Button
                                    onClick={() => {
                                        this.prevWeek()
                                    }}
                                >
                                    <Icon name="arrow left" />
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.nextWeek()
                                    }}
                                >
                                    <Icon name="arrow right" />
                                </Button>
                            </View>
                        )}
                        {this.renderMenu(_menu)}
                    </Menu>
                </Mobile>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
        workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
        workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let { workday, login } = state

    return {
        workday,
        login,
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SideBar)))
