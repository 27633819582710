import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Modal, Placeholder, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article, StockRecap } from '../components'

class Stock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            wait: true,
            deposit: -1,
            exportModal: false,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteArticle, deposit, page, items } = this.state
        let { organization } = this.props

        if (
            deposit !== prevState.deposit ||
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteArticle.fetching !== prevState.deleteArticle.fetching && !deleteArticle.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getArticleList(page, items, this.state.search.data, deposit)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getArticleList(page, items, this.state.search.data, deposit)
        }
    }

    next() {
        let { stock, items } = this.state

        if (!stock.data || stock.data.length < items) {
            return
        }

        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub, network } = this.props
        let { deposit, page, items } = this.state
        if (!sub) {
            this.props.toolbarResult([
                // {
                // 	name: 'Crea nuovo articolo',
                // 	icon: 'add',
                // 	// disabled: () => true,
                // 	action: () => {
                // 		this.props.history.push(route_from(app, stock, newEl, stockEdit))
                // 	}
                // },
                {
                    name: 'Esporta articoli',
                    icon: 'download',
                    position: 'right',
                    action: () => {
                        this.setState({ exportModal: true })
                    },
                },
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'none',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: 'none',
                    action: () => {
                        this.next()
                    },
                },
            ])
        }

        network.getDepositList()
        network.getArticleList(page, items, this.state.search.data, deposit)
    }

    renderArticle(key, article) {
        let { selected } = this.state
        let { onChange, selectable, noActions, showNoArticle } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (article) => {
                if (onChange) {
                    onChange(article)
                }
                this.setState({ selected: article })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoArticle) {
            extraProps.showNoArticle = true
        }

        return <Article {...extraProps} selected={article.code === selected.code} type="full" key={`article_${key}`} article={article} hideBarcode/>
    }

    render() {
        let { stock, fetching, deposit, depositList } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        // console.log('Deposit list is', depositList)
        let options = []

        if (depositList) {
            for (let d of depositList) {
                options.push({ key: d.id, value: d.id, text: d.name })
            }
        } else depositList = []

        options.push({ key: -1, value: -1, text: 'Tutti' })

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (stock.data) {
            /* eslint-disable */
            console.log('Stock data is', stock)
            content = stock.data.map((value, index) => {
                return this.renderArticle(value.articleId, value)
            })
        }

        return [
            <View row fullw key="stockHeader" around style={{ marginTop: 10 }}>
                <Form>
                    <Form.Group inline widths="equal">
                        <Form.Field
                            id="form-input-control-deposit"
                            control={Select}
                            options={options}
                            label={<span className="label">Deposito</span>}
                            placeholder="Deposito"
                            name="deposit"
                            value={deposit}
                            onChange={(e, data) => {
                                let dep = data.value
                                // console.log('Deposit selected', dep)
                                this.setState({ deposit: dep })
                            }}
                        />
                    </Form.Group>
                </Form>
            </View>,
            <View key={`stock-content`} fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                <Box textStyle="group" style={{ paddingTop: 16 }} centered>
                    {content}
                </Box>
                {this.renderExportModal()}
            </View>,
        ]
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    renderExportModal() {
        let { exportModal, wait, deposit } = this.state

        return (
            <Modal open={exportModal}>
                <Modal.Header>Esportazione articoli</Modal.Header>
                <Modal.Content>
                    {deposit !== -1 ? `Esportazione articoli dal deposito ${deposit}` : `Esportazione articoli da tutti i depositi`}
                    Premi il pulsante calcola per avviare il processo, poi scarica quando e{"'"} tutto pronto
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: true, exportModal: false })
                        }}
                    >
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: false })
                        }}
                    >
                        Calcola <Icon name="computer" />
                    </Button>
                    <StockRecap
                        deposit={deposit}
                        wait={wait}
                        onDone={(data) => {
                            // console.log('On done called', data)
                            this.setState({ wait: true })
                        }}
                        element={
                            <Button
                                primary
                                icon
                                onClick={() => {
                                    this.setState({ wait: true, exportModal: false })
                                }}
                            >
                                Scarica <Icon name="download" />
                            </Button>
                        }
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getArticleList, getDepositList, getRecentArticleList, deleteArticle, search, organization } = state
    let { isEmployee } = ownProps

    let articleList = getArticleList
    if (isEmployee) {
        articleList = getRecentArticleList
    }
    return {
        depositList: getDepositList.data.deposit,
        stock: articleList.data,
        fetching: articleList.fetching,
        deleteArticle,
        search,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Stock)))
