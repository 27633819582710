import { Box, Stack } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Select, Step } from 'semantic-ui-react'
import { Article, Job, PickFile, PickImage } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import ArticleDropdown from '../utility/ArticleDropdown'
import CustomerDropdown from '../utility/CustomerDropdown'
import { routes, route_from } from 'lib/routes'
import { createAlert } from 'lib/util'
import { Switch } from '@chakra-ui/react'
const { app, printjobs } = routes

const defaultJob = {
    jobCode: moment().add(360, 'days').format('DD-MM-YYYY'),
    description: '',
    jobType: 3,
    client: 'Cliente',
    image: '',
    urgencyLevel: 0,
    createdExternal: 0,
    billed: 0,
    active: 1,
    budgeted: 0,
    jobProgram: '',
    deliveryDate: new Date(),
    deliveryWeek: moment().get('week'),
    orderDate: new Date(),
    customerId: 1,
}

function JobWizard(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee, success, history } = props
    const [step, setStep] = useState(0)
    const [typeOptions, setTypeOptions] = useState([])
    const [job, setJob] = useState(defaultJob)
    const [articles, setArticles] = useState([{}])
    const [articleCount, setArticleCount] = useState(1)
    const [autoId, setAutoId] = useState(true)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
        network.getJobTypeList().then((result) => {
            const types = result.data
            let options = []
            for (let i in types) {
                let type = types[i]
                options.push({
                    key: type.jobType,
                    text: type.typeName === 'Stampaggio' ? 'Produzione' : type.typeName,
                    value: type.jobType,
                })
            }

            setTypeOptions(options)
        })
    }, [])

    const handleInput = (event) => {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (name === 'deliveryWeek') {
            if (value < 1) value = 1
            if (value > 53) value = 53
        }

        setJob({ ...job, [name]: value })

        if (name === 'deliveryWeek') {
            setJob({
                ...job,
                deliveryWeek: value,
                deliveryDate: moment().set('week', value).toDate(),
            })
        }
    }

    const articleDropdowns = []
    for (let i = 0; i < articleCount; i++) {
        articleDropdowns.push(
            <Stack direction={['column', 'row']} spacing="24px" horizontal key={`ArticleDropdownWizardSegmentGroup_${i}`}>
                <Box w={600} p={6} key={`ArticleDropdownWizardSegment_${i}`}>
                    <ArticleDropdown
                        key={`ArticleDropdownWizard_${i}`}
                        selected={articles}
                        onArticleSelected={(ev, data, selected, selectedArticle) => {
                            articles[i] = selectedArticle
                            setArticles(articles)
                        }}
                        defaultSelectedArticles={articles[i]}
                        single
                    />
                </Box>
                <Box p={6}>
                    <Button
                        key={`ArticleDropdownWizardBtnRmv_${i}`}
                        onClick={() => {
                            articles.splice(i, 1)
                            setArticles(articles)
                            setArticleCount(articleCount - 1)
                        }}
                    >
                        Rimuovi articolo
                    </Button>
                </Box>
            </Stack>
        )
    }

    return (
        <>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="tablet alternate" />
                    <Step.Content>
                        <Step.Title>Dati generali ordine cliente</Step.Title>
                        <Step.Description>Data di chiusura prevista, cliente, immagine</Step.Description>
                    </Step.Content>
                </Step>

                {job.jobType === 3 && (
                    <Step active={step === 1}>
                        <Icon name="cube" />
                        <Step.Content>
                            <Step.Title>Articoli ordinati</Step.Title>
                            <Step.Description>Quantità e data di completamento</Step.Description>
                        </Step.Content>
                    </Step>
                )}

                <Step active={step === 2}>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description>Procedura completa</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            {step === 0 && (
                <>
                    <Form success={job.status === 'success'} error={job.status === 'error'} warning={job.status === 'warning'}>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-job-id"
                                control={Input}
                                label={<span className="label">Codice ordine cliente</span>}
                                name="jobCode"
                                value={job.jobCode}
                                // disabled={!!id || updating}
                                onChange={(e) => handleInput(e)}
                                placeholder="Ordine cliente"
                                disabled={autoId}
                            />
                            <Form.Field
                                id="form-input-control-job-description"
                                control={Input}
                                label={<span className="label">Descrizione</span>}
                                placeholder="Fresature"
                                name="description"
                                value={job.description}
                                onChange={(e) => handleInput(e)}
                            />
                            <Form.Field
                                id="form-input-control-job-type"
                                control={Select}
                                options={typeOptions}
                                label={<span className="label">Tipo commessa</span>}
                                placeholder="Tipo commessa"
                                name="jobType"
                                value={job.jobType}
                                onChange={(e, data) => {
                                    let id = data.value
                                    setJob({ ...job, jobType: id })
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Switch}
                                label={<span className="label">Genera automaticamente codice commessa</span>}
                                defaultChecked={autoId}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setJob({ ...job, jobCode: moment().add(360, 'days').format('DD-MM-YYYY') })
                                        setAutoId(true)
                                    } else {
                                        setJob({ ...job, jobCode: '' })
                                        setAutoId(false)
                                    }
                                }}
                            />
                            <Form.Field
                                id="form-input-control-job-delivery-week"
                                control={Input}
                                label={<span className="label">Settimana di consegna</span>}
                                placeholder="32"
                                name="deliveryWeek"
                                value={job.deliveryWeek}
                                onChange={(e) => handleInput(e)}
                                disabled={job.jobType === 3}
                            />
                            <Form.Field
                                id="form-input-control-job-delivery-date"
                                control={ReactDatePicker}
                                label={<span className="label">Data di completamento</span>}
                                name="deliveryDate"
                                value={job.deliveryDate}
                                dateFormat="dd/MM/yyyy"
                                selected={job.deliveryDate}
                                disabled={job.jobType === 3}
                                onChange={(data) => {
                                    let deliveryWeek = moment(data).get('week')

                                    setJob({
                                        ...job,
                                        deliveryDate: data,
                                        deliveryWeek,
                                    })
                                }}
                            />
                            <Form.Field
                                id="form-input-control-job-order-date"
                                control={ReactDatePicker}
                                label={<span className="label">Data ordine</span>}
                                name="orderDate"
                                value={job.orderDate}
                                dateFormat="dd/MM/yyyy"
                                selected={job.orderDate}
                                onChange={(data) => {
                                    setJob({
                                        ...job,
                                        orderDate: data,
                                    })
                                }}
                                autoComplete="off"
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-textarea-control-image"
                                control={Input}
                                label={<span className="label">Immagine</span>}
                                placeholder="Immagine"
                                name="image"
                                value={job.image}
                                disabled
                                onChange={(e) => handleInput(e)}
                            />
                            <PickImage
                                onChange={(image) => {
                                    setJob({ ...job, image: image })
                                }}
                                value={job.image}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-textarea-control-job-program"
                                control={Input}
                                label={<span className="label">Programma</span>}
                                placeholder="Programma"
                                name="jobProgram"
                                value={job.jobProgram}
                                disabled
                                onChange={(e) => handleInput(e)}
                            />
                            <PickFile
                                onChange={(jobProgram) => {
                                    setJob({ ...job, jobProgram: jobProgram })
                                }}
                                value={job.jobProgram}
                            />
                        </Form.Group>
                        {/* <Form.Group widths="equal" style={{ margin: 0 }}> */}
                        <Box p={6}>
                            {/* <Form.Field
                            id="form-textarea-control-client"
                            control={Input}
                            label={<span className="label">Cliente</span>}
                            placeholder="Cliente"
                            name="client"
                            value={client}
                            onChange={(e) => handleInput(e)}
                        /> */}
                            <Box textStyle="header" as="h1">
                                Cliente
                            </Box>
                            <CustomerDropdown
                                single={true}
                                onChange={(ev, data, selected, customers, displayName) => {
                                    setJob({
                                        ...job,
                                        customerId: selected,
                                        client: displayName,
                                    })
                                }}
                                defaultSelectedCustomers={job.customerId}
                            />
                            {/* </Form.Group> */}
                        </Box>
                    </Form>
                </>
            )}
            {step === 1 && (
                <>
                    {articleDropdowns}
                    <Button
                        onClick={() => {
                            setArticleCount(articleCount + 1)
                            articles.push({})
                            setArticles(articles)
                        }}
                    >
                        Aggiungi articolo
                    </Button>
                </>
            )}

            {step === 2 && (
                <>
                    <Stack direction={['column', 'row']} spacing="24px">
                        <Box p={6}>
                            <Box textStyle="header">Dati anagrafici</Box>
                            <Job noRequests noActions type="full" job={job} />
                        </Box>

                        {job.jobType === 3 && (
                            <Box p={6}>
                                <Box textStyle="header">Dati articoli</Box>
                                {/* <Table> */}
                                {/* <Article type="header" /> */}
                                {articles.map((value, index) => {
                                    return <Article key={`article-${value.articleId}`} noActions type="full" article={value} />
                                })}
                                {/* </Table> */}
                            </Box>
                        )}
                    </Stack>
                </>
            )}
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && (
                    <Button
                        onClick={() => {
                            if (step === 2 && job.jobType !== 3) {
                                setStep(step - 2)
                            } else {
                                setStep(step - 1)
                            }
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {step < 2 && (
                    <Button
                        onClick={() => {
                            if (step === 0 && job.jobType !== 3) {
                                setStep(step + 2)
                            } else {
                                setStep(step + 1)
                            }
                        }}
                    >
                        Avanti
                    </Button>
                )}
                {step === 2 && (
                    <Button
                        color="green"
                        onClick={() => {
                            if (autoId) {
                                job.jobCode = moment().add(360, 'days').format('DD-MM-YYYY')
                            }

                            const toSave = {
                                ...job,
                                deliveryDate: job.deliveryDate
                                    ? moment(job.deliveryDate).add('hour', 2).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD'),
                            }
                            delete toSave.deliveryWeek
                            network.createJob(job.jobCode, toSave).then((result) => {
                                for (const article of articles) {
                                    if (article.articleId) {
                                        network.createWorkOrder({
                                            workCode: article.articleCode,
                                            jobCode: result.jobCode,
                                            clientOrderCode: result.jobCode,
                                            product: article.articleCode,
                                            productQuantity: article.quantity,
                                            remainingQuantity: article.quantity,
                                            producedQuantity: 0,
                                            deliveredQuantity: 0,
                                            articleId: article.articleId,
                                            completed: 0,
                                            deliveryDate: moment(article.deliveryDate).format('YYYY-MM-DD'),
                                        })
                                    }
                                }
                                success(
                                    createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                                        history.push(route_from(app, printjobs))
                                    })
                                )
                            })
                            setJob(defaultJob)
                            setArticles([{}])
                            setStep(0)
                        }}
                    >
                        Fine
                    </Button>
                )}
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(JobWizard)))
