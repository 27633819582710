import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import MoldEditor from '../job/MoldEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Mold from './Mold'
function Molds(props) {
    const {
        network,
        table,
        time,
        label,
        field,
        format,
        toolbarResult,
        filtersResult,
        sortsResult,
        deleteMold,
        mergeMold,
        searchText,
        sub,
        login,
    } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        mold: null,
    })

    const [molds, setMolds] = useState([])
    const [count, setCount] = useState(0)
    const [dirty, setDirty] = useState(false)
    const [editing, setEditing] = useState(false)
    const [creating, setCreating] = useState(false)
    const [filters, setFilters] = useState({})
    const [sorts, setSorts] = useState({})
    const [page, setPage] = useState(0)
    const pageRef = useRef()
    const countRef = useRef()

    const items = 10

    pageRef.current = page
    countRef.current = count

    useEffect(() => {
        network.getMoldList(searchText ? searchText : '', false, filters, sorts, page, page + 10).then((data) => {
            const molds = []
            for (const key in data.data) {
                molds.push(data.data[key])
            }
            setCount(data.count)
            setMolds(molds)
            setDirty(false)
        })
    }, [dirty, deleteMold.fetching, mergeMold.fetching, page])

    useEffect(() => {
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo stampo',
                icon: 'add',
                action: () => {
                    setCreating(true)
                },
            })
            // extraTools.push({
            //     name: 'Importa stampi da file',
            //     icon: 'check',
            //     action: () => {
            //         this.setState({
            //             showImport: true,
            //         })
            //     },
            // })
        }

        const toolbar = sub
            ? []
            : [
                  ...extraTools,
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: function () {
                          if (pageRef.current > 0) {
                              setPage(pageRef.current - 1)
                          }
                      },
                  },
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: function () {
                          if (pageRef.current * 10 + 10 < countRef.current) {
                              setPage(pageRef.current + 1)
                          }
                      },
                  },
              ]

        const sortoptions = {
            moldCode: {
                name: 'moldCode',
                label: 'Codice commessa',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMolds([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMolds([])
                    setDirty(true)
                },
            },
            creationDate: {
                name: 'creationDate',
                label: 'Data di creazione',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setMolds([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setMolds([])
                    setDirty(true)
                },
            },
        }

        const filteroptions = {
            moldCode: {
                control: Input,
                name: 'moldCode',
                input: 'text',
                placeholder: 'Codice stampo',
                label: 'Codice stampo',
                onChange: (e) => {
                    filters.moldCode = { value: e.target.value, key: 'moldCode', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setMolds([])
                    setDirty(true)
                },
            },
        }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [])

    if (!molds || molds.length === 0) return null

    return (
        <>
            <Box ml={16} mt={4}>
                Visualizzando {page * items} - {page * items + items} su {count}
            </Box>
            <Table celled sortable>
                <Mold
                    type="header"
                    sorts={sorts}
                    onMoldCodeHeaderClick={() => {
                        if (!sorts.moldCode) {
                            sorts.moldCode = 'asc'
                        } else if (sorts.moldCode === 'asc') {
                            sorts.moldCode = 'desc'
                        } else {
                            delete sorts.moldCode
                        }
                        setSorts(sorts)
                        setMolds([])
                        setDirty(true)
                    }}
                />
                <Table.Body>
                    {molds.map((value, index) => {
                        return (
                            <Mold
                                key={value.moldCode}
                                type="table"
                                mold={value}
                                onRowClick={(state) => {
                                    const { moldCode } = state
                                    setEditing(moldCode)
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setEditing(false)
                    setCreating(false)
                }}
            >
                <Modal.Header>{creating ? 'Creando stampo' : 'Modificando stampo'}</Modal.Header>
                <Modal.Content>
                    <MoldEditor
                        moldId={creating ? null : editing}
                        onSave={(mold) => {
                            setEditing(false)
                            setCreating(false)
                            setMolds([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                            setCreating(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList,
        deleteMold,
        mergeMold,
        search: { data: searchText },
        login,
    } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
        searchText,
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Molds)))
